import { Component, OnInit } from '@angular/core';
import { IClass } from 'src/app/interfaces/IClass';
import { ISchool } from 'src/app/interfaces/ISchool';
import { ITeacher } from 'src/app/interfaces/ITeacher';
import { AdminService } from 'src/app/services';
import { TranslateService } from '@ngx-translate/core';
import { AppState } from 'src/app/services/app.service';

@Component({

  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})

export class CommentsComponent implements OnInit {

  public unknownPage: string = 'admin_tr.teacher_comments';

  public week: number | null = null;
  public day: number | null = null;  
  public school: ISchool; 

  public content: any;
  public answers: any[];
  public loading: boolean = false;

  // filters
  public listAgeGroups: {key: string, title: string}[];
  public listClasses: IClass[];
  public listTeachers: ITeacher[];
  public weeks: Array<number> = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52]
  public days: Array<number> = [0,1,2,3,4,5]
  
  public currentTeacher: ITeacher = null;
  public currentAgeGroup: {key: string, title: string} = null;
  public currentClass: IClass = null;

  constructor(private appState: AppState, private translateService: TranslateService, private adminService: AdminService)   { } 

  ngOnInit(): void {
  
    this.listAgeGroups = this.adminService.getAgeGroup();
    this.school = this.appState.restore('school');
  }


  public getAgeGroup(age_group: string) {

    let age = this.adminService.getAgeGroup().find((x) => x.key === age_group);

    return age ? age.title : null;
  }

  public onChangeSchool(event: ISchool) {
    if (event) {
      this.school = event;
  
      this.currentAgeGroup = null;
      this.currentTeacher = null;
      this.currentClass = null;
      this.day =  this.days[0];
      this.week =  this.weeks[0];
      console.log(this.day,this.week)
      this.content = null;

      if (this.school && this.currentAgeGroup) {
        if (this.currentAgeGroup.key) {
          this.getTeachers(this.school.idnumber, this.currentAgeGroup.key);
        }
      } 
    }
  }

  private getTeachers(schoolId, ageId): void {
    this.loading = true;
    this.adminService.getTeachersByAgeAndSchool(schoolId, ageId).then((teachers: ITeacher[]) => {

      this.listTeachers = Object.assign([], teachers.sort((a, b) => (a.firstname + a.lastname < b.firstname + b.lastname ? -1 : 1)));    
      this.listTeachers.unshift({birthdate: null, email: null, firstname: this.translateService.instant('admin_shared.all_teachers'), lastname: null, username: null}); 
      this.currentTeacher =  this.listTeachers[0];
      
      this.getClasses()

      }).catch((error) => {
        console.log('Error get teacher: ', error);
      });
    
  }

  private getClasses(){ 
    this.loading = true;
    this.adminService.getActiveClassesByAgeSchoolAndTeacher(this.school.idnumber, this.currentAgeGroup.key, this.currentTeacher.username).then((classes: IClass[]) => {
      
      console.log(classes)
      this.listClasses = Object.assign([], classes.sort((a, b) => (a.name < b.name ? -1 : 1)));
      
      this.listClasses.unshift({
        date_begin: null,
        date_end: null,
        idnumber: null,
        main_tchr_first: null,
        main_tchr_last: null,
        main_tchr_username: null,
        name: this.translateService.instant('admin_shared.all_classes'),
        age_group: null
      });

      this.currentClass = this.listClasses[0];
      
      this.loading = false;
    })
  }

  public onChange(type: string){

    if(type == 'ageGroup'){

      this.currentClass = null;
      this.content = null;
      this.week = 0;
      this.day = 0; 
      this.getTeachers(this.school.idnumber, this.currentAgeGroup.key)
    }
    if(type == 'teacher') {
      
      this.currentClass = null;
      this.content = null;
      this.week = 0;
      this.day = 0;
      this.getClasses()
    }
    if(type == 'class') {
      
      this.content = null;
      this.day = 0;
      this.week = 0;
    }
    if(type == 'week') {

      if(this.week == 0){
        this.day = 0;
      }
    }
    if(type == 'day') {}
  }


  public load() {

    this.loading = true; 
    const week = this.week == 0 ?  null : this.week;
    const day = this.day == 0 ?  null : this.day;
    this.adminService.getCommentsBySchoolAgeTeacherClassWeekDay(this.school.idnumber, this.currentAgeGroup.key ,this.currentTeacher.username , this.currentClass.idnumber, week,day)
    .then((comments) => {
        this.content = comments;
        this.loading = false;
    })
    .catch((error) => {
        console.error('Failed to load comments:', error);
    });
   
  }

  public goTo(type:string){
    if(this.day != 0){
      if (type == '+'){
        if (this.day == 5){
          this.day = 1;
          if (this.week == 52){
            this.week = 1;
          } else{
              this.week++
          }
        } else{
          this.day++;
        }
      }
      if (type == '-'){
        if (this.day == 1){
          this.day = 5;
          if (this.week == 1){
            this.week = 52;
          } else{
              this.week--
          }
        } else{
          this.day--;
        }
      }  
    } else{
      if(type == '+'){
        if(this.week == 52) {
          this.week = 1;
        } else{
          this.week++
        }
      }
      if(type == '-'){
        if(this.week == 1) {
          this.week = 52;
        } else{
          this.week--
        }
      }
    }
      
    this.load()
  }

}
