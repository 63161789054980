import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AppState } from 'src/app/services/app.service';
import { map } from 'rxjs/operators';
import { ISchool } from 'src/app/interfaces/ISchool';

@Injectable({
    providedIn: 'root',
})
export class TeacherService {

    private apiRoot: string = environment.api;
    private cdnRoot: string = environment.cdn;

    private classesCache: Class[];
    private studentsCache: { clas: string, students: Student[] }[] = [];
  

    constructor(private http: HttpClient,
        private appState: AppState) {
        // empty        
    }

    /** Get list of ages */
    public getAges() {

        return [
            { key: 'in', title: 'Infant', titleKey: 'age_group.in', subtitle: 'Birth - 12 Months', subtitleKey: 'age_group.0_12' },
            { key: 't1', title: 'Younger Toddler',  titleKey: 'age_group.t1',subtitle: '12 - 24 Months', subtitleKey: 'age_group.12_24' },
            { key: 't2', title: 'Older Toddler',  titleKey: 'age_group.t2', subtitle: '24 - 36 Months', subtitleKey: 'age_group.24_36'},
            { key: 'ps', title: 'Preschool 3',  titleKey: 'age_group.ps', subtitle: '36 - 48 Months', subtitleKey: 'age_group.36_48'},
            { key: 'pk', title: 'Pre-K 4',  titleKey: 'age_group.pk', subtitle: '48 - 60 Months', subtitleKey: 'age_group.48_60'}
        ]
    }
    /** Get list of modes */
    public getModes() {
       
        return [
            { key: 'lp', titleKey: 'teacher_home.lesson_plans', title: 'Weekly Overview'  },
            { key: 'da', titleKey: 'teacher_home.daily_activities', title: 'Lesson Plans' },
            { key: 'tr', titleKey: 'teacher_home.teacher_reflections', title: 'Teacher Reflections'  },
            { key: 'hc', titleKey: 'teacher_home.home_connections', title: '@Home Connections'},
            { key: 'rs', titleKey: 'teacher_home.resources', title: 'Resources' },
            { key: 'mt', titleKey: 'teacher_home.materials', title: 'Materials' },
            { key: 'as', titleKey: 'teacher_home.assessment', title: 'Assessment' },
            { key: 'training', titleKey: 'teacher_home.training', title: 'Training'}
        ]
    }

    public getSectionsResources() {
       
        return [            
            { src: 'Teachers-Guide.jpg',  
              key: 'teacher_guide',   
              title: 'Teacher’s Guide',
              titleKey: 'resources.tg.title'
            },
            { src: 'Scope-and-Sequence.jpg',  
              key: 'scope&sequence',  
              title: 'Scope & Sequence',
              titleKey: 'resources.scope_sequence'
            }, 
            { src: 'Curriculum-Map.jpg',   
              key: 'curriculum_map', 
              title: 'Curriculum Map',
              titleKey: 'resources.curriculum_map'
            },           
            { src: 'Monthly-Themes.jpg', 
              key: 'monthly_themes', 
              title: 'Monthly Themes',
              titleKey: 'resources.monthly_themes'
            },
            {
                src: 'Pacing-Guide.png', 
                key: 'pacing_guide', 
                title: 'Pacing Guide',
                age: 'pk',
                titleKey: 'resources.pacing_guide'
            },
            {
                src: 'standards.jpg',
                key: 'st',
                title: 'Standards',
                titleKey: 'resources.standards'
            },
            {
                src: 'Center.png',
                key: 'center',
                title: 'Center Training',
                titleKey: 'resources.center_training'
            },
            {
                src: 'In-Home.png',
                key: 'inhome',
                title: 'In-Home Training',
                titleKey: 'resources.in_home_training'
            }
        ]
    }
    /** Get list of sections */
    public getSections(age) {

        let array = [
            { iconKey: 's11', key: 'ulb', title: 'Using Learning Beyond',  titleKey: 'resources.tg.using_lb' },
            { iconKey: 's1', key: 'in', title: 'Introduction',  titleKey: 'resources.tg.introduction' },
            { iconKey: 's2', key: 'ch', title: 'Characteristics of Preschoolers' ,  titleKey: 'resources.tg.characteristics_of_preschoolers'},
            { iconKey: 's3', key: 'ro', title: 'Role of the Teacher' ,  titleKey: 'resources.tg.teacher_role'},
            { iconKey: 's4', key: 'ti', title: 'Teacher Interactions',  titleKey: 'resources.tg.ti.title'},
            { iconKey: 's5', key: 'cl', title: '21st Century Learners',  titleKey: 'resources.tg.21st_century_learners'},
            { iconKey: 's6', key: 'ce', title: 'Classroom Environment',  titleKey: 'resources.tg.ce.title'},
            { iconKey: 's7', key: 'cs', title: 'Classroom Structure',  titleKey: 'resources.tg.cs.title'},
            { iconKey: 's8', key: 'le', title: 'Learning Experiences',  titleKey: 'resources.tg.le.title'},
            { iconKey: 's9', key: 'ra', title: 'Role of Assessments',  titleKey: 'resources.tg.ra.title'},
            { iconKey: 's10', key: 'fe', title: 'Family Engagement',  titleKey: 'resources.tg.fe.title'}         
        ]
        if (age === 'ps' || age === 'pk') {
            array.push({ iconKey: 's13', key: 'teacher_guide', title: 'Teacher\'s Guide PDF', titleKey: 'resources.tg.fe.pdf' });
        } else {
            array.push({ iconKey: 's12', key: 'teacher_guide', title: 'Teacher\'s Guide PDF', titleKey: 'resources.tg.fe.pdf' }); 
        }
        return array;
    }

    public getResourcesForPDF() {
        return [
            {
                title: 'Curriculum Map',
                key: 'curriculum_map',
                titleKey:'resources.curriculum_map',
                resources: {
                    in: 'https://acrobat.adobe.com/id/urn:aaid:sc:US:a49f9bc1-4b69-4fe9-a9ba-69203a4c22a5',
                    t1: 'https://acrobat.adobe.com/id/urn:aaid:sc:US:a49f9bc1-4b69-4fe9-a9ba-69203a4c22a5',
                    t2: 'https://acrobat.adobe.com/id/urn:aaid:sc:US:a49f9bc1-4b69-4fe9-a9ba-69203a4c22a5',
                    ps: 'https://acrobat.adobe.com/id/urn:aaid:sc:US:a49f9bc1-4b69-4fe9-a9ba-69203a4c22a5',
                    pk: 'https://acrobat.adobe.com/id/urn:aaid:sc:US:a49f9bc1-4b69-4fe9-a9ba-69203a4c22a5',
                 //   in: this.cdnRoot + '/pdf/LBP_IN_Curriculum_Map.pdf',
                 //   t1: this.cdnRoot + '/pdf/LBP_T1_Curriculum_Map.pdf',
                 //   t2: this.cdnRoot + '/pdf/LBP_T2_Curriculum_Map.pdf',
                 //   ps: this.cdnRoot + '/pdf/LBP_PS_Curriculum_Map.pdf',
                 //   pk: this.cdnRoot + '/pdf/LBP_PK_Curriculum_Map.pdf'
                },
            },
            {
                title: 'Scope & Sequence',
                key: 'scope&sequence',
                titleKey:'resources.scope_sequence',
                resources: {
                    in: 'https://acrobat.adobe.com/id/urn:aaid:sc:US:29439a08-9000-46bd-b6ab-3ddeeb352e0d',
                    t1: 'https://acrobat.adobe.com/id/urn:aaid:sc:US:29439a08-9000-46bd-b6ab-3ddeeb352e0d',
                    t2: 'https://acrobat.adobe.com/id/urn:aaid:sc:US:29439a08-9000-46bd-b6ab-3ddeeb352e0d',
                    ps: 'https://acrobat.adobe.com/id/urn:aaid:sc:US:29439a08-9000-46bd-b6ab-3ddeeb352e0d',
                    pk: 'https://acrobat.adobe.com/id/urn:aaid:sc:US:29439a08-9000-46bd-b6ab-3ddeeb352e0d',
                  //  in: this.cdnRoot + '/pdf/LBP_IN_Scope&Sequence.pdf',
                  //  t1: this.cdnRoot + '/pdf/LBP_T1_Scope&Sequence.pdf',
                  //  t2: this.cdnRoot + '/pdf/LBP_T2_Scope&Sequence.pdf',
                  //  ps: this.cdnRoot + '/pdf/LBP_PS_Scope&Sequence.pdf',
                  //  pk: this.cdnRoot + '/pdf/LBP_PK_Scope&Sequence.pdf'
                }
            },
            {
                title: 'Monthly Themes',
                key: 'monthly_themes',
                titleKey:'resources.monthly_themes',
                resources: {
                    in: this.cdnRoot + '/pdf/LBP_IN_T1_T2_Monthly_Themes.pdf',
                    t1: this.cdnRoot + '/pdf/LBP_IN_T1_T2_Monthly_Themes.pdf',
                    t2: this.cdnRoot + '/pdf/LBP_IN_T1_T2_Monthly_Themes.pdf',
                    ps: this.cdnRoot + '/pdf/LBP_PK_PS_Monthly_Themes.pdf',
                    pk: this.cdnRoot + '/pdf/LBP_PK_PS_Monthly_Themes.pdf'
                }
            },
            {
                title: 'In-Home Training',
                key: 'inhome',
                titleKey:'resources.in_home_training',
                resources: {
                    in: 'https://www.learningbeyondpaper.com/getting-started-inhome',
                    t1: 'https://www.learningbeyondpaper.com/getting-started-inhome',
                    t2: 'https://www.learningbeyondpaper.com/getting-started-inhome',
                    ps: 'https://www.learningbeyondpaper.com/getting-started-inhome',
                    pk: 'https://www.learningbeyondpaper.com/getting-started-inhome'                
                }
            },
            {
                title: 'Center Training',
                key: 'center',
                titleKey:'resources.center_training',
                resources: {
                    in: 'https://www.learningbeyondpaper.com/getting-started-teachers',
                    t1: 'https://www.learningbeyondpaper.com/getting-started-teachers',
                    t2: 'https://www.learningbeyondpaper.com/getting-started-teachers',
                    ps: 'https://www.learningbeyondpaper.com/getting-started-teachers',
                    pk: 'https://www.learningbeyondpaper.com/getting-started-teachers'                
                }
            },
            {
                title: 'Teacher’s Guide',
                key: 'teacher_guide',
                titleKey:'resources.tg.title',
                resources: {
                    in: this.cdnRoot + '/pdf/LBP_Infant_Toddler_Teacher’s_Guide.pdf',
                    t1: this.cdnRoot + '/pdf/LBP_Infant_Toddler_Teacher’s_Guide.pdf',
                    t2: this.cdnRoot + '/pdf/LBP_Infant_Toddler_Teacher’s_Guide.pdf',
                    ps: this.cdnRoot + '/pdf/LBP_Preschool_Teacher’s_Guide.pdf',
                    pk: this.cdnRoot + '/pdf/LBP_Preschool_Teacher’s_Guide.pdf'
                }
            },
            {
                title: 'Pacing Guide',
                key: 'pacing_guide',
                titleKey: 'resources.pacing_guide',
                resources: {
                    in: this.cdnRoot + '/pdf/LBP_PK_PacingGuide.pdf',
                    t1: this.cdnRoot + '/pdf/LBP_PK_PacingGuide.pdf',
                    t2: this.cdnRoot + '/pdf/LBP_PK_PacingGuide.pdf',
                    ps: this.cdnRoot + '/pdf/LBP_PK_PacingGuide.pdf',
                    pk: this.cdnRoot + '/pdf/LBP_PK_PacingGuide.pdf'
                }
            },
        ];
    }
    /** Get list of resources for 'Teacher’s Guide' sections*/
    public getResources() {

        return [
            {
                title: '21st Century Learners',
                key: 'cl',
                titleKey: "resources.tg.21st_century_learners",
                resources_in_t1_t2: {
                    resource_1l: '.../../../../../../../assets/img/tg-sections-content/21st-Century-Learners_in_t1_t2/1l.jpg',
                    resource_1m: this.cdnRoot + '/video/Infant-Toddlers.21st-Century-Learners.mp4#t=17',
                    resource_1r: '.../../../../../../../assets/img/tg-sections-content/21st-Century-Learners_in_t1_t2/1r.jpg'
                },
                resources_ps_pk: {
                    resource_1l: '.../../../../../../../assets/img/tg-sections-content/21st-Century-Learners/left1.jpg',
                    resource_1m: this.cdnRoot + '/video/Preschool.21st-Century-Learners.mp4#t=17',
                    resource_1r: '.../../../../../../../assets/img/tg-sections-content/21st-Century-Learners/Pic-1-Right.jpg'
                }
            },
            {
                title: 'Characteristics of Preschoolers',
                key: 'ch',
                titleKey: "resources.tg.characteristics_of_preschoolers",
                resources_in_t1_t2: {
                    resource_1l: '.../../../../../../../assets/img/tg-sections-content/Characteristics-of-Preschoolers_in_t1_t2/1l.jpg',
                    resource_1m: this.cdnRoot + '/video/Preschool.Characteristics-of-a-Preschoolers.mp4#t=14',
                    resource_1r: '.../../../../../../../assets/img/tg-sections-content/Characteristics-of-Preschoolers_in_t1_t2/1r.jpg',
                },
                resources_ps_pk: {
                    resource_1l: '.../../../../../../../assets/img/tg-sections-content/Characteristics-of-Preschoolers/Pic-1-Left.jpg',
                    resource_1m: this.cdnRoot + '/video/Infant-Toddlers.Characteristics-of-a-Preschoolers.mp4#t=14',
                    resource_1r: '.../../../../../../../assets/img/tg-sections-content/Characteristics-of-Preschoolers/Pic-1-Right.jpg',
                    resource_2l: '.../../../../../../../assets/img/tg-sections-content/Characteristics-of-Preschoolers/Pic-2-Left.jpg',
                    resource_2m: '.../../../../../../../assets/img/tg-sections-content/Characteristics-of-Preschoolers/Pic-2-Middle.jpg',
                    resource_2r: '.../../../../../../../assets/img/tg-sections-content/Characteristics-of-Preschoolers/Pic-2-Right.jpg'
                }
            },
            {
                title: 'Introduction',
                key: 'in',
                titleKey: "resources.tg.introduction",
                resources_in_t1_t2: {
                    resource_1l: '.../../../../../../../assets/img/tg-sections-content/Introduction_in_t1_t2/1l.jpg',
                    resource_1m: this.cdnRoot + '/video/Infant-Toddlers.Introduction.mp4#t=5',
                    resource_1r: '.../../../../../../../assets/img/tg-sections-content/Introduction_in_t1_t2/1r.jpg'
                },
                resources_ps_pk: {
                    resource_1l: '.../../../../../../../assets/img/tg-sections-content/Introduction/1l.jpg',
                    resource_1m: this.cdnRoot + '/video/Preschool.Introduction.mp4#t=5',
                    resource_1r: '.../../../../../../../assets/img/tg-sections-content/Introduction/Pic-1-Right.jpg'
                }
            },
            {
                title: 'Role of the Teacher',
                key: 'ro',
                titleKey: "resources.tg.teacher_role",
                resources_in_t1_t2: {
                    resource_1l: '.../../../../../../../assets/img/tg-sections-content/Role-of-the-Teacher_in_t1_t2/1l.jpg',
                    resource_1m: this.cdnRoot + '/video/Infant-Toddlers.Role-of-the-Teacher.mp4#t=5',
                    resource_1r: '.../../../../../../../assets/img/tg-sections-content/Role-of-the-Teacher_in_t1_t2/1r.jpg',
                },
                resources_ps_pk: {
                    resource_1l: '.../../../../../../../assets/img/tg-sections-content/Role-of-the-Teacher/Pic-1-Left.jpg',
                    resource_1m: this.cdnRoot + '/video/Preschool.Role-of-the-Teacher.mp4#t=5',
                    resource_1r: '.../../../../../../../assets/img/tg-sections-content/Role-of-the-Teacher/Pic-1-Right.jpg',
                }
            },
            {
                title: 'Differentiated Instruction',
                key: 'instruction',
                titleKey: "resources.tg.dif_instruction",
                resources_in_t1_t2: {},
                resources_ps_pk: {
                    resource_1l: '.../../../../../../../assets/img/tg-sections-content/Teacher-Interactions/Sub-Tile-Differentiated-Instruction/Pic-1-Left.jpg',
                    resource_1m: '.../../../../../../../assets/img/tg-sections-content/Teacher-Interactions/Sub-Tile-Differentiated-Instruction/Pic-1-Middle.jpg',
                    resource_1r: '.../../../../../../../assets/img/tg-sections-content/Teacher-Interactions/Sub-Tile-Differentiated-Instruction/Pic-1-Right.jpg',
                    resource_2l: '.../../../../../../../assets/img/tg-sections-content/Teacher-Interactions/Sub-Tile-Differentiated-Instruction/Pic-2-Left.jpg',
                    resource_2m: '.../../../../../../../assets/img/tg-sections-content/Teacher-Interactions/Sub-Tile-Differentiated-Instruction/Pic-2-Middle.jpg',
                    resource_2r: '.../../../../../../../assets/img/tg-sections-content/Teacher-Interactions/Sub-Tile-Differentiated-Instruction/Pic-2-Right.jpg'
                }
            },
            {
                title: 'Inclusion',
                key: 'inclusion',
                titleKey: "resources.tg.ti.inclusion",
                resources_in_t1_t2: {
                    resource_1l: '.../../../../../../../assets/img/tg-sections-content/Teacher-Interactions_in_t1_t2/Sub-Tile-Inclusion/1l.jpg',
                    resource_1m: '.../../../../../../../assets/img/tg-sections-content/Teacher-Interactions_in_t1_t2/Sub-Tile-Inclusion/1m.jpg',
                    resource_1r: '.../../../../../../../assets/img/tg-sections-content/Teacher-Interactions_in_t1_t2/Sub-Tile-Inclusion/1r.jpg'
                },
                resources_ps_pk: {
                    resource_1l: '.../../../../../../../assets/img/tg-sections-content/Teacher-Interactions/Sub-Tile-Inclusion/Pic-1-Left.jpg',
                    resource_1m: '.../../../../../../../assets/img/tg-sections-content/Teacher-Interactions/Sub-Tile-Inclusion/Pic-1-Middle.jpg',
                    resource_1r: '.../../../../../../../assets/img/tg-sections-content/Teacher-Interactions/Sub-Tile-Inclusion/Pic-1-Right.jpg',
                    resource_2l: '.../../../../../../../assets/img/tg-sections-content/Teacher-Interactions/Sub-Tile-Inclusion/Pic-2-Left.jpg',
                    resource_2m: '.../../../../../../../assets/img/tg-sections-content/Teacher-Interactions/Sub-Tile-Inclusion/Pic-2-Middle.jpg',
                    resource_2r: '.../../../../../../../assets/img/tg-sections-content/Teacher-Interactions/Sub-Tile-Inclusion/Pic-2-Right.jpg'
                }
            },
            {
                title: 'Intentionality',
                key: 'intentionality',
                titleKey: "resources.tg.ti.intentionality",
                resources_in_t1_t2: {
                    resource_1l: '.../../../../../../../assets/img/tg-sections-content/Teacher-Interactions_in_t1_t2/Sub-Tile-Intentionality/1l.jpg',
                    resource_1m: '.../../../../../../../assets/img/tg-sections-content/Teacher-Interactions_in_t1_t2/Sub-Tile-Intentionality/1m.jpg',
                    resource_1r: '.../../../../../../../assets/img/tg-sections-content/Teacher-Interactions_in_t1_t2/Sub-Tile-Intentionality/1r.jpg',
                    resource_2l: '.../../../../../../../assets/img/tg-sections-content/Teacher-Interactions_in_t1_t2/Sub-Tile-Intentionality/2l.jpg',
                    resource_2m: '.../../../../../../../assets/img/tg-sections-content/Teacher-Interactions_in_t1_t2/Sub-Tile-Intentionality/2m.jpg',
                    resource_2r: '.../../../../../../../assets/img/tg-sections-content/Teacher-Interactions_in_t1_t2/Sub-Tile-Intentionality/2r.jpg'
                },
                resources_ps_pk: {
                    resource_1l: '.../../../../../../../assets/img/tg-sections-content/Teacher-Interactions/Sub-Tile-Intentionality/Pic-1-Left.jpg',
                    resource_1m: '.../../../../../../../assets/img/tg-sections-content/Teacher-Interactions/Sub-Tile-Intentionality/Pic-1-Middle.jpg',
                    resource_1r: '.../../../../../../../assets/img/tg-sections-content/Teacher-Interactions/Sub-Tile-Intentionality/Pic-1-Right.jpg',
                    resource_2l: '.../../../../../../../assets/img/tg-sections-content/Teacher-Interactions/Sub-Tile-Intentionality/Pic-2-Left.jpg',
                    resource_2m: '.../../../../../../../assets/img/tg-sections-content/Teacher-Interactions/Sub-Tile-Intentionality/Pic-2-Middle.jpg',
                    resource_2r: '.../../../../../../../assets/img/tg-sections-content/Teacher-Interactions/Sub-Tile-Intentionality/Pic-2-Right.jpg'
                }
            },
            {
                title: 'Interactions',
                key: 'interactions',
                titleKey: "resources.tg.ti.interactions",
                resources_in_t1_t2: {
                    resource_1l: '.../../../../../../../assets/img/tg-sections-content/Teacher-Interactions_in_t1_t2/Sub-Tile-Interactions/1l.jpg',
                    resource_1m: '.../../../../../../../assets/img/tg-sections-content/Teacher-Interactions_in_t1_t2/Sub-Tile-Interactions/1m.jpg',
                    resource_1r: '.../../../../../../../assets/img/tg-sections-content/Teacher-Interactions_in_t1_t2/Sub-Tile-Interactions/1r.jpg',
                    resource_2l: '.../../../../../../../assets/img/tg-sections-content/Teacher-Interactions_in_t1_t2/Sub-Tile-Interactions/2l.jpg',
                    resource_2m: '.../../../../../../../assets/img/tg-sections-content/Teacher-Interactions_in_t1_t2/Sub-Tile-Interactions/2m.jpg',
                    resource_2r: '.../../../../../../../assets/img/tg-sections-content/Teacher-Interactions_in_t1_t2/Sub-Tile-Interactions/2r.jpg',
                    resource_3l: '.../../../../../../../assets/img/tg-sections-content/Teacher-Interactions_in_t1_t2/Sub-Tile-Interactions/3l.jpg',
                    resource_3m: '.../../../../../../../assets/img/tg-sections-content/Teacher-Interactions_in_t1_t2/Sub-Tile-Interactions/3m.jpg',
                    resource_3r: '.../../../../../../../assets/img/tg-sections-content/Teacher-Interactions_in_t1_t2/Sub-Tile-Interactions/3r.jpg'
                },
                resources_ps_pk: {
                    resource_1l: '.../../../../../../../assets/img/tg-sections-content/Teacher-Interactions/Sub-Tile-Interactions/Pic-1-Left.jpg',
                    resource_1m: '.../../../../../../../assets/img/tg-sections-content/Teacher-Interactions/Sub-Tile-Interactions/Pic-1-Middle.jpg',
                    resource_1r: '.../../../../../../../assets/img/tg-sections-content/Teacher-Interactions/Sub-Tile-Interactions/Pic-1-Right.jpg',
                    resource_2l: '.../../../../../../../assets/img/tg-sections-content/Teacher-Interactions/Sub-Tile-Interactions/Pic-2-Left.jpg',
                    resource_2m: '.../../../../../../../assets/img/tg-sections-content/Teacher-Interactions/Sub-Tile-Interactions/Pic-2-Middle.jpg',
                    resource_2r: '.../../../../../../../assets/img/tg-sections-content/Teacher-Interactions/Sub-Tile-Interactions/Pic-2-Right.jpg'
                }
            },
            {
                title: 'Leading with Equity',
                key: 'leading',
                titleKey: "resources.tg.ti.leading",
                resources_in_t1_t2: {
                    resource_1l: '.../../../../../../../assets/img/tg-sections-content/Teacher-Interactions_in_t1_t2/Sub-Tile-Leading-with-Equity/1l.jpg',
                    resource_1m: '.../../../../../../../assets/img/tg-sections-content/Teacher-Interactions_in_t1_t2/Sub-Tile-Leading-with-Equity/1m.jpg',
                    resource_1r: '.../../../../../../../assets/img/tg-sections-content/Teacher-Interactions_in_t1_t2/Sub-Tile-Leading-with-Equity/1r.jpg'
                },
                resources_ps_pk: {
                    resource_1l: '.../../../../../../../assets/img/tg-sections-content/Teacher-Interactions/Sub-Tile-Leading-with-Equity/Pic-1-Left.jpg',
                    resource_1m: '.../../../../../../../assets/img/tg-sections-content/Teacher-Interactions/Sub-Tile-Leading-with-Equity/Pic-1-Middle.jpg',
                    resource_1r: '.../../../../../../../assets/img/tg-sections-content/Teacher-Interactions/Sub-Tile-Leading-with-Equity/Pic-1-Right.jpg',
                }
            },
            {
                title: 'Scaffolding',
                key: 'scaffolding',
                titleKey: "resources.tg.ti.scaffolding",
                resources_in_t1_t2: {
                    resource_1l: '.../../../../../../../assets/img/tg-sections-content/Teacher-Interactions_in_t1_t2/Sub-Tile-Scaffolding/1l.jpg',
                    resource_1m: '.../../../../../../../assets/img/tg-sections-content/Teacher-Interactions_in_t1_t2/Sub-Tile-Scaffolding/1m.jpg',
                    resource_1r: '.../../../../../../../assets/img/tg-sections-content/Teacher-Interactions_in_t1_t2/Sub-Tile-Scaffolding/1r.jpg'
                },
                resources_ps_pk: {
                    resource_1l: '.../../../../../../../assets/img/tg-sections-content/Teacher-Interactions/Sub-Tile-Scaffolding/Pic-1-Left.jpg',
                    resource_1m: '.../../../../../../../assets/img/tg-sections-content/Teacher-Interactions/Sub-Tile-Scaffolding/Pic-1-Middle.jpg',
                    resource_1r: '.../../../../../../../assets/img/tg-sections-content/Teacher-Interactions/Sub-Tile-Scaffolding/Pic-1-Right.jpg',
                    resource_2l: '.../../../../../../../assets/img/tg-sections-content/Teacher-Interactions/Sub-Tile-Scaffolding/2l.jpg',
                    resource_2m: '.../../../../../../../assets/img/tg-sections-content/Teacher-Interactions/Sub-Tile-Scaffolding/2m.jpg',
                    resource_2r: '.../../../../../../../assets/img/tg-sections-content/Teacher-Interactions/Sub-Tile-Scaffolding/2r.jpg'
                }
            },
            {
                title: 'Serve and Return',
                key: 'serve&return',
                titleKey: "resources.tg.ti.serve_return",
                resources_in_t1_t2: {
                    resource_1l: '.../../../../../../../assets/img/tg-sections-content/Teacher-Interactions_in_t1_t2/Sub-Tile-Serve-and-Return/1l.jpg',
                    resource_1m: '.../../../../../../../assets/img/tg-sections-content/Teacher-Interactions_in_t1_t2/Sub-Tile-Serve-and-Return/1m.jpg',
                    resource_1r: '.../../../../../../../assets/img/tg-sections-content/Teacher-Interactions_in_t1_t2/Sub-Tile-Serve-and-Return/1r.jpg'
                },
                resources_ps_pk: {}
            },
            {
                title: 'Curriculum',
                key: 'curriculum',
                titleKey: "resources.tg.ti.curriculum",
                resources_in_t1_t2: {
                    resource_1l: '.../../../../../../../assets/img/tg-sections-content/Teacher-Interactions_in_t1_t2/Sub-Tile-Curriculum/1l.jpg',
                    resource_1m: '.../../../../../../../assets/img/tg-sections-content/Teacher-Interactions_in_t1_t2/Sub-Tile-Curriculum/1m.jpg',
                    resource_1r: '.../../../../../../../assets/img/tg-sections-content/Teacher-Interactions_in_t1_t2/Sub-Tile-Curriculum/1r.jpg',
                    resource_2l: '.../../../../../../../assets/img/tg-sections-content/Teacher-Interactions_in_t1_t2/Sub-Tile-Curriculum/2l.jpg',
                    resource_2m: '.../../../../../../../assets/img/tg-sections-content/Teacher-Interactions_in_t1_t2/Sub-Tile-Curriculum/2m.jpg',
                    resource_2r: '.../../../../../../../assets/img/tg-sections-content/Teacher-Interactions_in_t1_t2/Sub-Tile-Curriculum/2r.jpg'
                },
                resources_ps_pk: {}
            },
            {
                title: 'Introduction',
                key: 'introduction',
                titleKey: "resources.tg.ce.introduction",
                resources_in_t1_t2: {
                    resource_1l: '.../../../../../../../assets/img/tg-sections-content/Classroom-Environment_in_t1_t2/Sub-Tile-Introduction/1l.jpg',
                    resource_1m: '.../../../../../../../assets/img/tg-sections-content/Classroom-Environment_in_t1_t2/Sub-Tile-Introduction/1m.jpg',
                    resource_1r: '.../../../../../../../assets/img/tg-sections-content/Classroom-Environment_in_t1_t2/Sub-Tile-Introduction/1r.jpg',
                },
                resources_ps_pk: {
                    resource_1l: '.../../../../../../../assets/img/tg-sections-content/Classroom-Environment/Sub-Tile-Introduction/Pic-1-Left.jpg',
                    resource_1m: '.../../../../../../../assets/img/tg-sections-content/Classroom-Environment/Sub-Tile-Introduction/Pic-1-Middle.jpg',
                    resource_1r: '.../../../../../../../assets/img/tg-sections-content/Classroom-Environment/Sub-Tile-Introduction/Pic-1-Right.jpg',
                }
            },
            {
                title: 'Designing the Indoor and Outdoor Environment',
                key: 'indoor&outdoor',
                titleKey: "resources.tg.ce.indoor_outdoor_des",
                resources_in_t1_t2: {
                    resource_1l: '.../../../../../../../assets/img/tg-sections-content/Classroom-Environment_in_t1_t2/Sub-Tile-Indoor&Outdoor/1l.jpg',
                    resource_1m: this.cdnRoot + '/video/Classroom-Environment-IndoorOutdoor.mp4#t=11',
                    resource_1r: '.../../../../../../../assets/img/tg-sections-content/Classroom-Environment_in_t1_t2/Sub-Tile-Indoor&Outdoor/1r.jpg',
                    resource_2l: '.../../../../../../../assets/img/tg-sections-content/Classroom-Environment_in_t1_t2/Sub-Tile-Indoor&Outdoor/2l.jpg',
                    resource_2m: '.../../../../../../../assets/img/tg-sections-content/Classroom-Environment_in_t1_t2/Sub-Tile-Indoor&Outdoor/2m.jpg',
                    resource_2r: '.../../../../../../../assets/img/tg-sections-content/Classroom-Environment_in_t1_t2/Sub-Tile-Indoor&Outdoor/2r.jpg'
                },
                resources_ps_pk: {
                    resource_1l: '.../../../../../../../assets/img/tg-sections-content/Classroom-Environment/Sub-Tile-Indoor&Outdoor/Pic-1-Left.jpg',
                    resource_1m: this.cdnRoot + '/video/Classroom-Environment-IndoorOutdoor.mp4#t=11',
                    resource_1r: '.../../../../../../../assets/img/tg-sections-content/Classroom-Environment/Sub-Tile-Indoor&Outdoor/Pic-1-Right.jpg',
                    resource_2l: '.../../../../../../../assets/img/tg-sections-content/Classroom-Environment/Sub-Tile-Indoor&Outdoor/Pic-2-Left.jpg',
                    resource_2m: '.../../../../../../../assets/img/tg-sections-content/Classroom-Environment/Sub-Tile-Indoor&Outdoor/Pic-2-Middle.jpg',
                    resource_2r: '.../../../../../../../assets/img/tg-sections-content/Classroom-Environment/Sub-Tile-Indoor&Outdoor/Pic-2-Right.jpg',
                    resource_3l: '.../../../../../../../assets/img/tg-sections-content/Classroom-Environment/Sub-Tile-Indoor&Outdoor/Pic-3-Left.jpg',
                    resource_3m: '.../../../../../../../assets/img/tg-sections-content/Classroom-Environment/Sub-Tile-Indoor&Outdoor/Pic-3-Middle.jpg',
                    resource_3r: '.../../../../../../../assets/img/tg-sections-content/Classroom-Environment/Sub-Tile-Indoor&Outdoor/Pic-3-Right.jpg'
                }
            },
            {
                title: 'Health and Safety',
                key: 'health&safety',
                titleKey: "resources.tg.ce.health_safety",
                resources_in_t1_t2: {
                    resource_1l: '.../../../../../../../assets/img/tg-sections-content/Classroom-Environment_in_t1_t2/Sub-Tile-Health&Safety/1l.jpg',
                    resource_1m: '.../../../../../../../assets/img/tg-sections-content/Classroom-Environment_in_t1_t2/Sub-Tile-Health&Safety/1m.jpg',
                    resource_1r: '.../../../../../../../assets/img/tg-sections-content/Classroom-Environment_in_t1_t2/Sub-Tile-Health&Safety/1r.jpg',
                },
                resources_ps_pk: {
                    resource_1l: '.../../../../../../../assets/img/tg-sections-content/Classroom-Environment/Sub-Tile-Health&Safety/Pic-1-Left.jpg',
                    resource_1m: '.../../../../../../../assets/img/tg-sections-content/Classroom-Environment/Sub-Tile-Health&Safety/Pic-1-Middle.jpg',
                    resource_1r: '.../../../../../../../assets/img/tg-sections-content/Classroom-Environment/Sub-Tile-Health&Safety/Pic-1-Right.jpg'
                }
            },
            {
                title: 'Involving Children',
                key: 'involving',
                titleKey: "resources.tg.ce.involving_children",
                resources_in_t1_t2: {
                    resource_1l: '.../../../../../../../assets/img/tg-sections-content/Classroom-Environment_in_t1_t2/Sub-Tile-Involving-Children/1l.jpg',
                    resource_1m: '.../../../../../../../assets/img/tg-sections-content/Classroom-Environment_in_t1_t2/Sub-Tile-Involving-Children/1m.jpg',
                    resource_1r: '.../../../../../../../assets/img/tg-sections-content/Classroom-Environment_in_t1_t2/Sub-Tile-Involving-Children/1r.jpg',
                    resource_2l: '.../../../../../../../assets/img/tg-sections-content/Classroom-Environment_in_t1_t2/Sub-Tile-Involving-Children/2l.jpg',
                    resource_2m: '.../../../../../../../assets/img/tg-sections-content/Classroom-Environment_in_t1_t2/Sub-Tile-Involving-Children/2m.jpg',
                    resource_2r: '.../../../../../../../assets/img/tg-sections-content/Classroom-Environment_in_t1_t2/Sub-Tile-Involving-Children/2r.jpg'
                },
                resources_ps_pk: {
                    resource_1l: '.../../../../../../../assets/img/tg-sections-content/Classroom-Environment/Sub-Tile-Involving-Children/Pic-1-Left.jpg',
                    resource_1m: '.../../../../../../../assets/img/tg-sections-content/Classroom-Environment/Sub-Tile-Involving-Children/Pic-1-Middle.jpg',
                    resource_1r: '.../../../../../../../assets/img/tg-sections-content/Classroom-Environment/Sub-Tile-Involving-Children/Pic-1-Right.jpg',
                    resource_2l: '.../../../../../../../assets/img/tg-sections-content/Classroom-Environment/Sub-Tile-Involving-Children/Pic-2-Left.jpg',
                    resource_2m: '.../../../../../../../assets/img/tg-sections-content/Classroom-Environment/Sub-Tile-Involving-Children/Pic-2-Middle.jpg',
                    resource_2r: '.../../../../../../../assets/img/tg-sections-content/Classroom-Environment/Sub-Tile-Involving-Children/Pic-2-Right.jpg'
                }
            },
            {
                title: 'Setting Up Centers',
                key: 'centers',
                titleKey: "resources.tg.ce.setting_centers",
                resources_in_t1_t2: {
                    resource_1l: '.../../../../../../../assets/img/tg-sections-content/Classroom-Environment_in_t1_t2/Sub-Tile-Centers/1l.jpg',
                    resource_1m: '.../../../../../../../assets/img/tg-sections-content/Classroom-Environment_in_t1_t2/Sub-Tile-Centers/1m.jpg',
                    resource_1r: '.../../../../../../../assets/img/tg-sections-content/Classroom-Environment_in_t1_t2/Sub-Tile-Centers/1r.jpg',
                    resource_2l: '.../../../../../../../assets/img/tg-sections-content/Classroom-Environment_in_t1_t2/Sub-Tile-Centers/2l.jpg',
                    resource_2m: '.../../../../../../../assets/img/tg-sections-content/Classroom-Environment_in_t1_t2/Sub-Tile-Centers/2m.jpg',
                    resource_2r: '.../../../../../../../assets/img/tg-sections-content/Classroom-Environment_in_t1_t2/Sub-Tile-Centers/2r.jpg',
                    resource_3l: '.../../../../../../../assets/img/tg-sections-content/Classroom-Environment_in_t1_t2/Sub-Tile-Centers/3l.jpg',
                    resource_3m: '.../../../../../../../assets/img/tg-sections-content/Classroom-Environment_in_t1_t2/Sub-Tile-Centers/3m.jpg',
                    resource_3r: '.../../../../../../../assets/img/tg-sections-content/Classroom-Environment_in_t1_t2/Sub-Tile-Centers/3r.jpg'
                },
                resources_ps_pk: {
                    resource_1l: '.../../../../../../../assets/img/tg-sections-content/Classroom-Environment/Sub-Tile-Centers/Pic-1-Left.jpg',
                    resource_1m: '.../../../../../../../assets/img/tg-sections-content/Classroom-Environment/Sub-Tile-Centers/Pic-1-Middle.jpg',
                    resource_1r: '.../../../../../../../assets/img/tg-sections-content/Classroom-Environment/Sub-Tile-Centers/Pic-1-Right.jpg',
                    resource_2l: '.../../../../../../../assets/img/tg-sections-content/Classroom-Environment/Sub-Tile-Centers/2l.jpg',
                    resource_2m: '.../../../../../../../assets/img/tg-sections-content/Classroom-Environment/Sub-Tile-Centers/2m.jpg',
                    resource_2r: '.../../../../../../../assets/img/tg-sections-content/Classroom-Environment/Sub-Tile-Centers/2r.jpg',
                    resource_3l: '.../../../../../../../assets/img/tg-sections-content/Classroom-Environment/Sub-Tile-Centers/3l.jpg',
                    resource_3m: '.../../../../../../../assets/img/tg-sections-content/Classroom-Environment/Sub-Tile-Centers/3m.jpg',
                    resource_3r: '.../../../../../../../assets/img/tg-sections-content/Classroom-Environment/Sub-Tile-Centers/3r.jpg',
                    resource_4l: '.../../../../../../../assets/img/tg-sections-content/Classroom-Environment/Sub-Tile-Centers/Pic-4-Left.jpg',
                    resource_4m: '.../../../../../../../assets/img/tg-sections-content/Classroom-Environment/Sub-Tile-Centers/Pic-4-Middle.jpg',
                    resource_4r: '.../../../../../../../assets/img/tg-sections-content/Classroom-Environment/Sub-Tile-Centers/Pic-4-Right.jpg'
                }
            },
            {
                title: 'Setting up Shared-Use Spaces',
                key: 'spaces',
                titleKey: "resources.tg.ce.setting_spaces",
                resources_in_t1_t2: {
                    resource_1l: '.../../../../../../../assets/img/tg-sections-content/Classroom-Environment_in_t1_t2/Sub-Tile-Shared-Use-Spaces/1l.jpg',
                    resource_1m: '.../../../../../../../assets/img/tg-sections-content/Classroom-Environment_in_t1_t2/Sub-Tile-Shared-Use-Spaces/1m.jpg',
                    resource_1r: '.../../../../../../../assets/img/tg-sections-content/Classroom-Environment_in_t1_t2/Sub-Tile-Shared-Use-Spaces/1r.jpg'
                },
                resources_ps_pk: {
                    resource_1l: '.../../../../../../../assets/img/tg-sections-content/Classroom-Environment/Sub-Tile-Shared-Use-Spaces/Pic-1-Left.jpg',
                    resource_1m: '.../../../../../../../assets/img/tg-sections-content/Classroom-Environment/Sub-Tile-Shared-Use-Spaces/Pic-1-Middle.jpg',
                    resource_1r: '.../../../../../../../assets/img/tg-sections-content/Classroom-Environment/Sub-Tile-Shared-Use-Spaces/Pic-1-Right.jpg'
                }
            },
            {
                title: 'Technology',
                key: 'technology',
                titleKey: "resources.tg.ce.technology",
                resources_in_t1_t2: {
                    resource_1l: '.../../../../../../../assets/img/tg-sections-content/Classroom-Environment_in_t1_t2/Sub-Tile-Technology-in-the-Classroom/1l.jpg',
                    resource_1m: '.../../../../../../../assets/img/tg-sections-content/Classroom-Environment_in_t1_t2/Sub-Tile-Technology-in-the-Classroom/1m.jpg',
                    resource_1r: '.../../../../../../../assets/img/tg-sections-content/Classroom-Environment_in_t1_t2/Sub-Tile-Technology-in-the-Classroom/1r.jpg',
                },
                resources_ps_pk: {
                    resource_1l: '.../../../../../../../assets/img/tg-sections-content/Classroom-Environment/Sub-Tile-Technology-in-the-Classroom/Pic-1-Left.jpg',
                    resource_1m: '.../../../../../../../assets/img/tg-sections-content/Classroom-Environment/Sub-Tile-Technology-in-the-Classroom/Pic-1-Middle.jpg',
                    resource_1r: '.../../../../../../../assets/img/tg-sections-content/Classroom-Environment/Sub-Tile-Technology-in-the-Classroom/Pic-1-Right.jpg',
                    resource_2l: '.../../../../../../../assets/img/tg-sections-content/Classroom-Environment/Sub-Tile-Technology-in-the-Classroom/Pic-2-Left.jpg',
                    resource_2m: '.../../../../../../../assets/img/tg-sections-content/Classroom-Environment/Sub-Tile-Technology-in-the-Classroom/Pic-2-Middle.jpg',
                    resource_2r: '.../../../../../../../assets/img/tg-sections-content/Classroom-Environment/Sub-Tile-Technology-in-the-Classroom/Pic-2-Right.jpg'
                }
            },
            {
                title: 'Classroom Community',
                key: 'community',
                titleKey: "resources.tg.cs.classroom_community",
                resources_in_t1_t2: {
                    resource_1l: '.../../../../../../../assets/img/tg-sections-content/Classrom-Structure_in_t1_t2/Sub-Tile-Classroom-Community/1l.jpg',
                    resource_1m: '.../../../../../../../assets/img/tg-sections-content/Classrom-Structure_in_t1_t2/Sub-Tile-Classroom-Community/1m.jpg',
                    resource_1r: '.../../../../../../../assets/img/tg-sections-content/Classrom-Structure_in_t1_t2/Sub-Tile-Classroom-Community/1r.jpg',
                },
                resources_ps_pk: {
                    resource_1l: '.../../../../../../../assets/img/tg-sections-content/Classrom-Structure/Sub-Tile-Classroom-Community/Pic-1-Left.jpg',
                    resource_1m: '.../../../../../../../assets/img/tg-sections-content/Classrom-Structure/Sub-Tile-Classroom-Community/Pic-1-Middle.jpg',
                    resource_1r: '.../../../../../../../assets/img/tg-sections-content/Classrom-Structure/Sub-Tile-Classroom-Community/Pic-1-Right.jpg',
                    resource_2l: '.../../../../../../../assets/img/tg-sections-content/Classrom-Structure/Sub-Tile-Classroom-Community/Pic-2-Left.jpg',
                    resource_2m: '.../../../../../../../assets/img/tg-sections-content/Classrom-Structure/Sub-Tile-Classroom-Community/Pic-2-Middle.jpg',
                    resource_2r: '.../../../../../../../assets/img/tg-sections-content/Classrom-Structure/Sub-Tile-Classroom-Community/Pic-2-Right.jpg'
                }
            },
            {
                title: 'Routines',
                key: 'routine',
                titleKey: "resources.tg.cs.routines",
                resources_in_t1_t2: {
                    resource_1l: '.../../../../../../../assets/img/tg-sections-content/Classrom-Structure_in_t1_t2/Sub-Tile-Routines/1l.jpg',
                    resource_1m: '.../../../../../../../assets/img/tg-sections-content/Classrom-Structure_in_t1_t2/Sub-Tile-Routines/1m.jpg',
                    resource_1r: '.../../../../../../../assets/img/tg-sections-content/Classrom-Structure_in_t1_t2/Sub-Tile-Routines/1r.jpg',
                    resource_2l: '.../../../../../../../assets/img/tg-sections-content/Classrom-Structure_in_t1_t2/Sub-Tile-Routines/2l.jpg',
                    resource_2m: '.../../../../../../../assets/img/tg-sections-content/Classrom-Structure_in_t1_t2/Sub-Tile-Routines/2m.jpg',
                    resource_2r: '.../../../../../../../assets/img/tg-sections-content/Classrom-Structure_in_t1_t2/Sub-Tile-Routines/2r.jpg',
                    resource_3l: '.../../../../../../../assets/img/tg-sections-content/Classrom-Structure_in_t1_t2/Sub-Tile-Routines/3l.jpg',
                    resource_3m: '.../../../../../../../assets/img/tg-sections-content/Classrom-Structure_in_t1_t2/Sub-Tile-Routines/3m.jpg',
                    resource_3r: '.../../../../../../../assets/img/tg-sections-content/Classrom-Structure_in_t1_t2/Sub-Tile-Routines/3r.jpg',
                    resource_4l: '.../../../../../../../assets/img/tg-sections-content/Classrom-Structure_in_t1_t2/Sub-Tile-Routines/4l.jpg',
                    resource_4m: '.../../../../../../../assets/img/tg-sections-content/Classrom-Structure_in_t1_t2/Sub-Tile-Routines/4m.jpg',
                    resource_4r: '.../../../../../../../assets/img/tg-sections-content/Classrom-Structure_in_t1_t2/Sub-Tile-Routines/4r.jpg'
                },
                resources_ps_pk: {
                    resource_1l: '.../../../../../../../assets/img/tg-sections-content/Classrom-Structure/Sub-Tile-Routines/Pic-1-Left.jpg',
                    resource_1m: '.../../../../../../../assets/img/tg-sections-content/Classrom-Structure/Sub-Tile-Routines/Pic-1-Middle.jpg',
                    resource_1r: '.../../../../../../../assets/img/tg-sections-content/Classrom-Structure/Sub-Tile-Routines/Pic-1-Right.jpg',
                    resource_2l: '.../../../../../../../assets/img/tg-sections-content/Classrom-Structure/Sub-Tile-Routines/Pic-2-Left.jpg',
                    resource_2m: '.../../../../../../../assets/img/tg-sections-content/Classrom-Structure/Sub-Tile-Routines/Pic-2-Middle.jpg',
                    resource_2r: '.../../../../../../../assets/img/tg-sections-content/Classrom-Structure/Sub-Tile-Routines/Pic-2-Right.jpg',
                    resource_3l: '.../../../../../../../assets/img/tg-sections-content/Classrom-Structure/Sub-Tile-Routines/Pic-3-Left.jpg',
                    resource_3m: '.../../../../../../../assets/img/tg-sections-content/Classrom-Structure/Sub-Tile-Routines/Pic-3-Middle.jpg',
                    resource_3r: '.../../../../../../../assets/img/tg-sections-content/Classrom-Structure/Sub-Tile-Routines/Pic-3-Right.jpg'
                }
            },
            {
                title: 'Schedule',
                key: 'schedule',
                titleKey: "resources.tg.cs.schedule",
                resources_in_t1_t2: {
                    resource_1l: '.../../../../../../../assets/img/tg-sections-content/Classrom-Structure_in_t1_t2/Sub-Tile-Schedule/1l.jpg',
                    resource_1m: '.../../../../../../../assets/img/tg-sections-content/Classrom-Structure_in_t1_t2/Sub-Tile-Schedule/1m.jpg',
                    resource_1r: '.../../../../../../../assets/img/tg-sections-content/Classrom-Structure_in_t1_t2/Sub-Tile-Schedule/1r.jpg',
                    resource_2l: '.../../../../../../../assets/img/tg-sections-content/Classrom-Structure_in_t1_t2/Sub-Tile-Schedule/2l.jpg',
                    resource_2m: '.../../../../../../../assets/img/tg-sections-content/Classrom-Structure_in_t1_t2/Sub-Tile-Schedule/2m.jpg',
                    resource_2r: '.../../../../../../../assets/img/tg-sections-content/Classrom-Structure_in_t1_t2/Sub-Tile-Schedule/2r.jpg',
                    resource_3l: '.../../../../../../../assets/img/tg-sections-content/Classrom-Structure_in_t1_t2/Sub-Tile-Schedule/3l.jpg',
                    resource_3m: '.../../../../../../../assets/img/tg-sections-content/Classrom-Structure_in_t1_t2/Sub-Tile-Schedule/3m.jpg',
                    resource_3r: '.../../../../../../../assets/img/tg-sections-content/Classrom-Structure_in_t1_t2/Sub-Tile-Schedule/3r.jpg',
                },
                resources_ps_pk: {
                    resource_1l: '.../../../../../../../assets/img/tg-sections-content/Classrom-Structure/Sub-Tile-Schedule/Pic-1-Left.jpg',
                    resource_1m: '.../../../../../../../assets/img/tg-sections-content/Classrom-Structure/Sub-Tile-Schedule/Pic-1-Middle.jpg',
                    resource_1r: '.../../../../../../../assets/img/tg-sections-content/Classrom-Structure/Sub-Tile-Schedule/Pic-1-Right.jpg',
                    resource_2l: '.../../../../../../../assets/img/tg-sections-content/Classrom-Structure/Sub-Tile-Schedule/Pic-2-Left.jpg',
                    resource_2m: '.../../../../../../../assets/img/tg-sections-content/Classrom-Structure/Sub-Tile-Schedule/Pic-2-Middle.jpg',
                    resource_2r: '.../../../../../../../assets/img/tg-sections-content/Classrom-Structure/Sub-Tile-Schedule/Pic-2-Right.jpg',
                }
            },
            {
                title: 'Complexity and Depth',
                key: 'complexity&depth',
                titleKey: "resources.tg.le.complexity_depth",
                resources_in_t1_t2: {
                    resource_1l: '.../../../../../../../assets/img/tg-sections-content/Learning-Experiences_in_t1_t2/Sub-Tile-Complexity-and-Depth/1l.jpg',
                    resource_1m: '.../../../../../../../assets/img/tg-sections-content/Learning-Experiences_in_t1_t2/Sub-Tile-Complexity-and-Depth/1m.jpg',
                    resource_1r: '.../../../../../../../assets/img/tg-sections-content/Learning-Experiences_in_t1_t2/Sub-Tile-Complexity-and-Depth/1r.jpg',
                },
                resources_ps_pk: {
                    resource_1l: '.../../../../../../../assets/img/tg-sections-content/Learning-Experiences/Sub-Tile-Complexity-and-Depth/1_l.jpg',
                    resource_1m: '.../../../../../../../assets/img/tg-sections-content/Learning-Experiences/Sub-Tile-Complexity-and-Depth/1_m.jpg',
                    resource_1r: '.../../../../../../../assets/img/tg-sections-content/Learning-Experiences/Sub-Tile-Complexity-and-Depth/1_r.jpg'
                }
            },
            {
                title: 'Linking the Standards',
                key: 'linking',
                titleKey: "resources.tg.le.linking_standards",
                resources_in_t1_t2: {
                    resource_1l: '.../../../../../../../assets/img/tg-sections-content/Learning-Experiences_in_t1_t2/Sub-Tile-Linking-the-Standards/1l.jpg',
                    resource_1m: '.../../../../../../../assets/img/tg-sections-content/Learning-Experiences_in_t1_t2/Sub-Tile-Linking-the-Standards/1m.jpg',
                    resource_1r: '.../../../../../../../assets/img/tg-sections-content/Learning-Experiences_in_t1_t2/Sub-Tile-Linking-the-Standards/1r.jpg',
                },
                resources_ps_pk: {
                    resource_1l: '.../../../../../../../assets/img/tg-sections-content/Learning-Experiences/Sub-Tile-Linking-the-Standards/1_l.jpg',
                    resource_1m: '.../../../../../../../assets/img/tg-sections-content/Learning-Experiences/Sub-Tile-Linking-the-Standards/1_m.jpg',
                    resource_1r: '.../../../../../../../assets/img/tg-sections-content/Learning-Experiences/Sub-Tile-Linking-the-Standards/1_r.jpg'
                }
            },
            {
                title: 'Planning and Reflection',
                key: 'planning&reflection',
                titleKey: "resources.tg.le.planning_reflection",
                resources_in_t1_t2: {
                    resource_1l: '.../../../../../../../assets/img/tg-sections-content/Learning-Experiences_in_t1_t2/Sub-Tile-Planning-and-Reflection/1l.jpg',
                    resource_1m: '.../../../../../../../assets/img/tg-sections-content/Learning-Experiences_in_t1_t2/Sub-Tile-Planning-and-Reflection/1m.jpg',
                    resource_1r: '.../../../../../../../assets/img/tg-sections-content/Learning-Experiences_in_t1_t2/Sub-Tile-Planning-and-Reflection/1r.jpg',
                },
                resources_ps_pk: {
                    resource_1l: '.../../../../../../../assets/img/tg-sections-content/Learning-Experiences/Sub-Tile-Planning-and-Reflection/1l.jpg',
                    resource_1m: '.../../../../../../../assets/img/tg-sections-content/Learning-Experiences/Sub-Tile-Planning-and-Reflection/1m.jpg',
                    resource_1r: '.../../../../../../../assets/img/tg-sections-content/Learning-Experiences/Sub-Tile-Planning-and-Reflection/1r.jpg'
                }
            },
            {
                title: 'Play and Authenticity',
                key: 'play&authenticity',
                titleKey: "resources.tg.le.play_authenticity",
                resources_in_t1_t2: {
                    resource_1l: '.../../../../../../../assets/img/tg-sections-content/Learning-Experiences_in_t1_t2/Sub-Tile-Play-and-Authenticity/1l.jpg',
                    resource_1m: '.../../../../../../../assets/img/tg-sections-content/Learning-Experiences_in_t1_t2/Sub-Tile-Play-and-Authenticity/1m.jpg',
                    resource_1r: '.../../../../../../../assets/img/tg-sections-content/Learning-Experiences_in_t1_t2/Sub-Tile-Play-and-Authenticity/1r.jpg',
                },
                resources_ps_pk: {
                    resource_1l: '.../../../../../../../assets/img/tg-sections-content/Learning-Experiences/Sub-Tile-Play-and-Authenticity/1_l.jpg',
                    resource_1m: '.../../../../../../../assets/img/tg-sections-content/Learning-Experiences/Sub-Tile-Play-and-Authenticity/1_m.jpg',
                    resource_1r: '.../../../../../../../assets/img/tg-sections-content/Learning-Experiences/Sub-Tile-Play-and-Authenticity/1_r.jpg'
                }
            },
            {
                title: 'Standards-Based Learning',
                key: 'sb_learning',
                titleKey: "resources.tg.le.sb_learning",
                resources_in_t1_t2: {
                    resource_1l: '.../../../../../../../assets/img/tg-sections-content/Learning-Experiences_in_t1_t2/Sub-Tile-Standards-Based-Learning/1l.jpg',
                    resource_1m: '.../../../../../../../assets/img/tg-sections-content/Learning-Experiences_in_t1_t2/Sub-Tile-Standards-Based-Learning/1m.jpg',
                    resource_1r: '.../../../../../../../assets/img/tg-sections-content/Learning-Experiences_in_t1_t2/Sub-Tile-Standards-Based-Learning/1r.jpg',
                },
                resources_ps_pk: {
                    resource_1l: '.../../../../../../../assets/img/tg-sections-content/Learning-Experiences/Sub-Tile-Standards-Based-Learning/1_l.jpg',
                    resource_1m: '.../../../../../../../assets/img/tg-sections-content/Learning-Experiences/Sub-Tile-Standards-Based-Learning/1_m.jpg',
                    resource_1r: '.../../../../../../../assets/img/tg-sections-content/Learning-Experiences/Sub-Tile-Standards-Based-Learning/1_r.jpg'
                }
            },
            {
                title: 'Child Assessment',
                key: 'child_level',
                titleKey: "resources.tg.ra.child_assessment",
                resources_in_t1_t2: {
                    resource_1l: '.../../../../../../../assets/img/tg-sections-content/Role-of-Assessment_in_t1_t2/Sub-Tile-Child-Level-Assessment/1l.jpg',
                    resource_1m: '.../../../../../../../assets/img/tg-sections-content/Role-of-Assessment_in_t1_t2/Sub-Tile-Child-Level-Assessment/1m.jpg',
                    resource_1r: '.../../../../../../../assets/img/tg-sections-content/Role-of-Assessment_in_t1_t2/Sub-Tile-Child-Level-Assessment/1r.jpg',
                    resource_2l: '.../../../../../../../assets/img/tg-sections-content/Role-of-Assessment_in_t1_t2/Sub-Tile-Child-Level-Assessment/2l.jpg',
                    resource_2m: '.../../../../../../../assets/img/tg-sections-content/Role-of-Assessment_in_t1_t2/Sub-Tile-Child-Level-Assessment/2m.jpg',
                    resource_2r: '.../../../../../../../assets/img/tg-sections-content/Role-of-Assessment_in_t1_t2/Sub-Tile-Child-Level-Assessment/2r.jpg'
                },
                resources_ps_pk: {
                    resource_1l: '.../../../../../../../assets/img/tg-sections-content/Role-of-Assessment/Sub-Tile-Child-Level-Assessment/1l.jpg',
                    resource_1m: '.../../../../../../../assets/img/tg-sections-content/Role-of-Assessment/Sub-Tile-Child-Level-Assessment/1m.jpg',
                    resource_1r: '.../../../../../../../assets/img/tg-sections-content/Role-of-Assessment/Sub-Tile-Child-Level-Assessment/1r.jpg',
                    resource_2l: '.../../../../../../../assets/img/tg-sections-content/Role-of-Assessment/Sub-Tile-Child-Level-Assessment/2l.jpg',
                    resource_2m: '.../../../../../../../assets/img/tg-sections-content/Role-of-Assessment/Sub-Tile-Child-Level-Assessment/2m.jpg',
                    resource_2r: '.../../../../../../../assets/img/tg-sections-content/Role-of-Assessment/Sub-Tile-Child-Level-Assessment/2r.jpg'
                }
            },
            {
                title: 'Introduction',
                key: 'ra_introduction',
                titleKey: "resources.tg.ra.introduction",
                resources_in_t1_t2: {
                    resource_1l: '.../../../../../../../assets/img/tg-sections-content/Role-of-Assessment_in_t1_t2/Sub-Tile-Introduction/1l.jpg',
                    resource_1m: '.../../../../../../../assets/img/tg-sections-content/Role-of-Assessment_in_t1_t2/Sub-Tile-Introduction/1m.jpg',
                    resource_1r: '.../../../../../../../assets/img/tg-sections-content/Role-of-Assessment_in_t1_t2/Sub-Tile-Introduction/1r.png'
                },
                resources_ps_pk: {
                    resource_1l: '.../../../../../../../assets/img/tg-sections-content/Role-of-Assessment/Sub-Tile-Introduction/1l.jpg',
                    resource_1m: '.../../../../../../../assets/img/tg-sections-content/Role-of-Assessment/Sub-Tile-Introduction/1m.jpg',
                    resource_1r: '.../../../../../../../assets/img/tg-sections-content/Role-of-Assessment/Sub-Tile-Introduction/1r.jpg'
                }
            },
            {
                title: 'Classroom Assessments',
                key: 'classroom_level',
                titleKey: "resources.tg.ra.classroom_assessment",
                resources_in_t1_t2: {},
                resources_ps_pk: {}
            },
            {
                title: 'Program Accreditation',
                key: 'accreditation',
                titleKey: "resources.tg.ra.program_accreditation",
                resources_in_t1_t2: {},
                resources_ps_pk: {}
            },
            {
                title: 'Program Assessment',
                key: 'program_level',
                titleKey: "resources.tg.ra.program_assessment",
                resources_in_t1_t2: {},
                resources_ps_pk: {}
            },
            {
                title: 'Authenticity',
                key: 'authenticity',
                titleKey: "resources.tg.fe.authenticity",
                resources_in_t1_t2: {
                    resource_1l: '.../../../../../../../assets/img/tg-sections-content/Family-Engagement_in_t1_t2/Sub-Tile-Authenticity/1l.jpg',
                    resource_1m: '.../../../../../../../assets/img/tg-sections-content/Family-Engagement_in_t1_t2/Sub-Tile-Authenticity/1m.jpg',
                    resource_1r: '.../../../../../../../assets/img/tg-sections-content/Family-Engagement_in_t1_t2/Sub-Tile-Authenticity/1r.jpg'
                },
                resources_ps_pk: {
                    resource_1l: '.../../../../../../../assets/img/tg-sections-content/Family-Engagement/Sub-Tile-Authenticity/1l.jpg',
                    resource_1m: '.../../../../../../../assets/img/tg-sections-content/Family-Engagement/Sub-Tile-Authenticity/1m.jpg',
                    resource_1r: '.../../../../../../../assets/img/tg-sections-content/Family-Engagement/Sub-Tile-Authenticity/1r.jpg'
                }
            },
            {
                title: 'Beginning Program',
                key: 'beginning',
                titleKey: "resources.tg.fe.beginning_program",
                resources_in_t1_t2: {
                    resource_1l: '.../../../../../../../assets/img/tg-sections-content/Family-Engagement_in_t1_t2/Sub-Tile-Beginning-Program/1l.jpg',
                    resource_1m: '.../../../../../../../assets/img/tg-sections-content/Family-Engagement_in_t1_t2/Sub-Tile-Beginning-Program/1m.jpg',
                    resource_1r: '.../../../../../../../assets/img/tg-sections-content/Family-Engagement_in_t1_t2/Sub-Tile-Beginning-Program/1r.jpg'
                },
                resources_ps_pk: {
                    resource_1l: '.../../../../../../../assets/img/tg-sections-content/Family-Engagement/Sub-Tile-Beginning-Program/1l.jpg',
                    resource_1m: '.../../../../../../../assets/img/tg-sections-content/Family-Engagement/Sub-Tile-Beginning-Program/1m.jpg',
                    resource_1r: '.../../../../../../../assets/img/tg-sections-content/Family-Engagement/Sub-Tile-Beginning-Program/1r.jpg'
                }
            },
            {
                title: 'Languages',
                key: 'languages',
                titleKey: "resources.tg.fe.languages",
                resources_in_t1_t2: {
                    resource_1l: '.../../../../../../../assets/img/tg-sections-content/Family-Engagement_in_t1_t2/Sub-Tile-Languages/1l.jpg',
                    resource_1m: '.../../../../../../../assets/img/tg-sections-content/Family-Engagement_in_t1_t2/Sub-Tile-Languages/1m.jpg',
                    resource_1r: '.../../../../../../../assets/img/tg-sections-content/Family-Engagement_in_t1_t2/Sub-Tile-Languages/1r.jpg'
                },
                resources_ps_pk: {
                    resource_1l: '.../../../../../../../assets/img/tg-sections-content/Family-Engagement/Sub-Tile-Languages/1l.jpg',
                    resource_1m: '.../../../../../../../assets/img/tg-sections-content/Family-Engagement/Sub-Tile-Languages/1m.jpg',
                    resource_1r: '.../../../../../../../assets/img/tg-sections-content/Family-Engagement/Sub-Tile-Languages/1r.jpg'
                }
            },
            {
                title: 'Partnering',
                key: 'partnering',
                titleKey: "resources.tg.fe.partnering",
                resources_in_t1_t2: {
                    resource_1l: '.../../../../../../../assets/img/tg-sections-content/Family-Engagement_in_t1_t2/Sub-Tile-Partnering/1l.jpg',
                    resource_1m: '.../../../../../../../assets/img/tg-sections-content/Family-Engagement_in_t1_t2/Sub-Tile-Partnering/1m.jpg',
                    resource_1r: '.../../../../../../../assets/img/tg-sections-content/Family-Engagement_in_t1_t2/Sub-Tile-Partnering/1r.jpg'
                },
                resources_ps_pk: {
                    resource_1l: '.../../../../../../../assets/img/tg-sections-content/Family-Engagement/Sub-Tile-Partnering/1l.jpg',
                    resource_1m: '.../../../../../../../assets/img/tg-sections-content/Family-Engagement/Sub-Tile-Partnering/1m.jpg',
                    resource_1r: '.../../../../../../../assets/img/tg-sections-content/Family-Engagement/Sub-Tile-Partnering/1r.jpg'
                }
            },
            {
                title: 'Perspectives',
                key: 'perspectives',
                titleKey: "resources.tg.fe.perspectives",
                resources_in_t1_t2: {
                    resource_1l: '.../../../../../../../assets/img/tg-sections-content/Family-Engagement_in_t1_t2/Sub-Tile-Understanding-Perspectives/1l.jpg',
                    resource_1m: '.../../../../../../../assets/img/tg-sections-content/Family-Engagement_in_t1_t2/Sub-Tile-Understanding-Perspectives/1m.jpg',
                    resource_1r: '.../../../../../../../assets/img/tg-sections-content/Family-Engagement_in_t1_t2/Sub-Tile-Understanding-Perspectives/1r.jpg'
                },
                resources_ps_pk: {
                    resource_1l: '.../../../../../../../assets/img/tg-sections-content/Family-Engagement/Sub-Tile-Understanding-Perspectives/1l.jpg',
                    resource_1m: '.../../../../../../../assets/img/tg-sections-content/Family-Engagement/Sub-Tile-Understanding-Perspectives/1m.jpg',
                    resource_1r: '.../../../../../../../assets/img/tg-sections-content/Family-Engagement/Sub-Tile-Understanding-Perspectives/1r.jpg'
                }
            },
            {
                title: 'Curriculum Map',
                key: 'curriculum_map',
                titleKey: "resources.curriculum_map",
                resources_in_t1_t2: {},
                resources_ps_pk: {}
            },
            {
                title: 'Scope & Sequence',
                key: 'scope&sequence',
                titleKey: "resources.scope_sequence",
                names: {
                    in: 'Infant – Scope & Sequence',
                    t1: 'Younger Toddler – Scope & Sequence',
                    t2: 'Older Toddler – Scope & Sequence',
                    ps: 'Preschool – Scope & Sequence',
                    pk: 'Pre-K – Scope & Sequence'
                },
                resources: {
                    in: this.cdnRoot + '/pdf/LBP_IN_Scope&Sequence.pdf',
                    t1: this.cdnRoot + '/pdf/LBP_T1_Scope&Sequence.pdf',
                    t2: this.cdnRoot + '/pdf/LBP_T2_Scope&Sequence.pdf',
                    ps: this.cdnRoot + '/pdf/LBP_PS_Scope&Sequence.pdf',
                    pk: this.cdnRoot + '/pdf/LBP_PK_Scope&Sequence.pdf'
                }
            },
            {
                title: 'Monthly Themes',
                key: 'monthly_themes',
                titleKey: "resources.monthly_themes",
                names: {
                    in: 'Infant – Monthly Themes',
                    t1: 'Younger Toddler – Monthly Themes',
                    t2: 'Older Toddler – Monthly Themes',
                    ps: 'Preschool – Monthly Themes',
                    pk: 'Pre-K – Monthly Themes'
                },
                resources: {
                    in: this.cdnRoot + '/pdf/LBP_IN_Scope&Sequence.pdf',
                    t1: this.cdnRoot + '/pdf/LBP_T1_Scope&Sequence.pdf',
                    t2: this.cdnRoot + '/pdf/LBP_T2_Scope&Sequence.pdf',
                    ps: this.cdnRoot + '/pdf/LBP_PS_Scope&Sequence.pdf',
                    pk: this.cdnRoot + '/pdf/LBP_PK_Scope&Sequence.pdf'
                }
            },
            {
                title: 'Teacher’s Guide',
                key: 'teacher_guide',
                titleKey: "resources.tg.title",
                resources_in_t1_t2: {
                    resource_1: this.cdnRoot + '/pdf/LBP_Infant_Toddler_Teacher’s_Guide.pdf'
                },
                resources_ps_pk: {
                    resource_1: this.cdnRoot + '/pdf/LBP_Preschool_Teacher’s_Guide.pdf',
                }
            },
            // WEBINAR VIDEOs

            {
                title: 'Webinar',
                key: 'ce_webinar',
                titleKey: "resources.webinar",
                resources_in_t1_t2: {
                    resource_1m: this.cdnRoot + '/video/Infant-Toddlers.Classroom-Environments.mp4#t=4'
                },
                resources_ps_pk: {
                    resource_1m: this.cdnRoot + '/video/Preschool.Classroom-Environments.mp4#t=4'
                }
            },
            {
                title: 'Webinar',
                titleKey: "resources.webinar",
                key: 'ti_webinar',
                resources_in_t1_t2: {
                    resource_1m: this.cdnRoot + '/video/Infant-Toddlers.Teacher-Interactions.mp4#t=4'
                },
                resources_ps_pk: {
                    resource_1m: this.cdnRoot + '/video/Preschool.Teacher-Interactions.mp4#t=4'
                }
            },
            {
                title: 'Webinar',
                titleKey: "resources.webinar",
                key: 'cs_webinar',
                resources_in_t1_t2: {
                    resource_1m: this.cdnRoot + '/video/Infant-Toddlers.Classroom-Structure.mp4#t=4'
                },
                resources_ps_pk: {
                    resource_1m: this.cdnRoot + '/video/Preschool.Classroom-Structure.mp4#t=4'
                }
            },
            {
                title: 'Webinar',
                titleKey: "resources.webinar",
                key: 'le_webinar',
                resources_in_t1_t2: {
                    resource_1m: this.cdnRoot + '/video/Infant-Toddlers.Learning-Experiences.mp4#t=4'
                },
                resources_ps_pk: {
                    resource_1m: this.cdnRoot + '/video/Preschool.Learning-Experiences.mp4#t=4'
                }
            },
            {
                title: 'Webinar',
                key: 'ra_webinar',
                titleKey: "resources.webinar",
                resources_in_t1_t2: {
                    resource_1m: this.cdnRoot + '/video/Infant-Toddlers.Role-of-Assessment.mp4#t=4'
                },
                resources_ps_pk: {
                    resource_1m: this.cdnRoot + '/video/Preschool.Role-of-Assessment.mp4#t=4'
                }
            },
            {
                title: 'Webinar',
                key: 'fe_webinar',
                titleKey: "resources.webinar",
                resources_in_t1_t2: {
                    resource_1m: this.cdnRoot + '/video/Infant-Toddlers.Family-Engagement.mp4#t=4'
                },
                resources_ps_pk: {
                    resource_1m: this.cdnRoot + '/video/Preschool.Family-Engagement.mp4#t=4'
                }
            },
            {
                title: 'Getting Started',
                titleKey: "resources.getting_started.",
                key: 'start',
                resources_in_t1_t2: {
                    resource_1m: this.cdnRoot + '/video/Infant-Toddlers.Using-Learning-Beyond.Getting-Started.mp4#t=4'
                },
                resources_ps_pk: {
                    resource_1m: this.cdnRoot + '/video/Preschool.Using-Learning-Beyond.Getting-Started.mp4#t=4'
                }
            },
            {
                title: 'Finding What You Need ',
                titleKey: "resources.tg.finding",
                key: 'finding',
                resources_in_t1_t2: {
                    resource_1m: this.cdnRoot + '/video/Infant-Toddlers.Finding-What-You-Need.mp4#t=4'
                },
                resources_ps_pk: {
                    resource_1m: this.cdnRoot + '/video/Preschool.Finding-What-You-Need.mp4#t=4'
                }
            }
        ]
    }

    public getSubTiles() {

        return [
            {
                title: 'Classroom Environment',
                folder: 'Sub-Tile-Classroom-Environment',
                key: 'ce',
                titleKey: 'resources.tg.ce.title',
                resources: [
                    {
                        title: 'Introduction',
                        key: 'introduction',
                        titleKey: 'resources.tg.ce.introduction',
                        src: 'Sub-Tile-Introduction.jpg'
                    },
                    {
                        title: 'Indoor & Outdoor',
                        key: 'indoor&outdoor',
                        titleKey: 'resources.tg.ce.indoor_outdoor',
                        src: 'Sub-Tile-Indoor&Outdoor.jpg'
                    },
                    {
                        title: 'Involving Children',
                        key: 'involving',
                        titleKey: 'resources.tg.ce.involving_children',
                        src: 'Sub-Tile-Involving-Children.jpg'
                    },
                    {
                        title: 'Centers',
                        key: 'centers',
                        titleKey: 'resources.tg.ce.centers',
                        src: 'Sub-Tile-Centers.jpg'
                    },
                    {
                        title: 'Shared Use Spaces',
                        key: 'spaces',
                        titleKey: 'resources.tg.ce.spaces',
                        src: 'Sub-Tile-Shared-Use-Spaces.jpg'
                    },
                    {
                        title: 'Technology',
                        key: 'technology',
                        titleKey: 'resources.tg.ce.technology',
                        src: 'Sub-Tile-Technology-in-the-Classroom.jpg'
                    },
                    {
                        title: 'Health & Safety',
                        key: 'health&safety',
                        titleKey: 'resources.tg.ce.health_safety',
                        src: 'Sub-Tile-Health&Safety.jpg'
                    },
                    {
                        title: 'Webinar',
                        key: 'ce_webinar',
                        titleKey: 'resources.tg.ce.webinar',
                        src: 'Sub-Tile-Webinar.jpg'
                    }
                ]
            },
            {
                title: 'Classroom Structure',
                folder: 'Sub-Tile-Classroom-Structure',
                key: 'cs',
                titleKey: 'resources.tg.cs.title',
                resources: [
                    {
                        title: 'Classroom Community',
                        key: 'community',
                        titleKey: 'resources.tg.cs.classroom_community',
                        src: 'Sub-Tile-Classroom-Community.jpg'
                    },
                    {
                        title: 'Routine',
                        key: 'routine',
                        titleKey: 'resources.tg.cs.routine',
                        src: 'Sub-Tile-Routine.jpg'
                    },
                    {
                        title: 'Schedule',
                        key: 'schedule',
                        titleKey: 'resources.tg.cs.schedule',
                        src: 'Sub-Tile-Schedule.jpg'
                    },
                    {
                        title: 'Webinar',
                        key: 'cs_webinar',
                        titleKey: 'resources.tg.cs.webinar',
                        src: 'Sub-Tile-Webinar.jpg'
                    }
                ]
            },
            {
                title: 'Teacher Interactions',
                folder: 'Sub-Tile-Teacher-Interactions',
                key: 'ti',
                titleKey: 'resources.tg.ti.title',
                resources: [
                    {
                        title: 'Intentionality',
                        key: 'intentionality',
                        titleKey: 'resources.tg.ti.intentionality',
                        src: 'Sub-Tile-Intentionality.jpg'
                    },
                    {
                        title: 'Interactions',
                        key: 'interactions',
                        titleKey: 'resources.tg.ti.interactions',
                        src: 'Sub-Tile-Interactions.jpg'
                    },
                    {
                        title: 'Leading with Equity',
                        key: 'leading',
                        titleKey: 'resources.tg.ti.leading',
                        src: 'Sub-Tile-Leading-with-Equity.jpg'
                    },
                    {
                        title: 'Inclusion',
                        key: 'inclusion',
                        titleKey: 'resources.tg.ti.inclusion',
                        src: 'Sub-Tile-Inclusion.jpg'
                    },
                    {
                        title: 'Differentiated Instruction',
                        key: 'instruction',
                        titleKey: 'resources.tg.dif_instruction',
                        src: 'Sub-Tile-Differentiated-Instruction.jpg'
                    },
                    {
                        title: 'Scaffolding',
                        key: 'scaffolding',
                        titleKey: 'resources.tg.ti.scaffolding',
                        src: 'Sub-Tile-Scaffolding.jpg'
                    },
                    {
                        title: 'Webinar',
                        key: 'ti_webinar',
                        titleKey: 'resources.tg.ti.webinar',
                        src: 'Sub-Tile-Webinar.jpg'
                    }
                ]
            },
            {
                title: 'Learning Experiences',
                folder: 'Sub-Tile-Learning-Experiences',
                titleKey: 'resources.tg.le.title',
                key: 'le',
                resources: [
                    {
                        title: 'Complexity and Depth',
                        key: 'complexity&depth',
                        titleKey: 'resources.tg.le.complexity_depth',
                        src: 'Sub-Tile-Complexity&Depth.jpg'
                    },
                    {
                        title: 'Linking the Standards',
                        key: 'linking',
                        titleKey: 'resources.tg.le.linking_standards',
                        src: 'Sub-Tile-Linking-the-Standards.jpg'
                    },
                    {
                        title: 'Planning and Reflection',
                        key: 'planning&reflection',
                        titleKey: 'resources.tg.le.planning_reflection',
                        src: 'Sub-Tile-Planning&Reflection.jpg'
                    },
                    {
                        title: 'Play and Authenticity',
                        key: 'play&authenticity',
                        titleKey: 'resources.tg.le.play_authenticity',
                        src: 'Sub-Tile-Play&Authenticity.jpg'
                    },
                    {
                        title: 'Standards-Based Learning',
                        key: 'sb_learning',
                        titleKey: 'resources.tg.le.sb_learning',
                        src: 'Sub-Tile-Standards-Based-Learning.jpg'
                    },
                    {
                        title: 'Webinar',
                        key: 'le_webinar',
                        titleKey: 'resources.tg.le.webinar',
                        src: 'Sub-Tile-Webinar.jpg'
                    }
                ]
            },
            {
                title: 'Role of Assessments',
                folder: 'Sub-Tile-Role-of-Assessments',
                titleKey: 'resources.tg.ra.title',
                key: 'ra',
                resources: [
                    {
                        title: 'Introduction',
                        key: 'ra_introduction',
                        titleKey: 'resources.tg.ra.introduction',
                        src: 'Sub-Tile-Introduction.jpg'
                    },
                    {
                        title: 'Child Assessment',
                        key: 'child_level',
                        titleKey: 'resources.tg.ra.child_assessment',
                        src: 'Sub-Tile-Child-Level-Assessment.jpg'
                    },
                    {
                        title: 'Classroom Assessment',
                        key: 'classroom_level',
                        titleKey: 'resources.tg.ra.classroom_assessment',
                        src: 'Sub-Tile-Classroom-Level-Assessment.jpg'
                    },
                    {
                        title: 'Program Accreditation',
                        key: 'accreditation',
                        titleKey: 'resources.tg.ra.program_accreditation',
                        src: 'Sub-Tile-Program-Accreditation.jpg'
                    },
                    {
                        title: 'Program Assessment',
                        key: 'program_level',
                        titleKey: 'resources.tg.ra.program_assessment',
                        src: 'Sub-Tile-Program-Level-Assessment.jpg'
                    },
                    {
                        title: 'Webinar',
                        key: 'ra_webinar',
                        titleKey: 'resources.tg.ra.webinar',
                        src: 'Sub-Tile-Webinar.jpg'
                    }
                ]
            },
            {
                title: 'Family Engagement',
                titleKey: 'resources.tg.fe.title',
                folder: 'Sub-Tile-Family-Engagement',
                key: 'fe',
                resources: [
                    {
                        title: 'Authenticity',
                        key: 'authenticity',
                        titleKey: 'resources.tg.fe.authenticity',
                        src: 'Sub-Tile-Authenticity.jpg'
                    },
                    {
                        title: 'Beginning Program',
                        key: 'beginning',
                        titleKey: 'resources.tg.fe.beginning_program',
                        src: 'Sub-Tile-Beginning-of-Program.jpg'
                    },
                    {
                        title: 'Languages',
                        key: 'languages',
                        titleKey: 'resources.tg.fe.languages',
                        src: 'Sub-Tile-Languages.jpg'
                    },
                    {
                        title: 'Partnering',
                        key: 'partnering',
                        titleKey: 'resources.tg.fe.partnering',
                        src: 'Sub-Tile-Partnering.jpg'
                    },
                    {
                        title: 'Perspectives',
                        key: 'perspectives',
                        titleKey: 'resources.tg.fe.perspectives',
                        src: 'Sub-Tile-Understanding-Perspectives.jpg'
                    },
                    {
                        title: 'Webinar',
                        key: 'fe_webinar',
                        titleKey: 'resources.tg.fe.webinar',
                        src: 'Sub-Tile-Webinar.jpg'
                    }
                ]
            },
            {
                title: 'Using Learning Beyond',
                folder: 'Sub-Tile-Using-Learning-Beyond',
                key: 'ulb',
                titleKey: 'resources.tg.using_lb',
                resources: [
                    {
                        title: 'Getting Started',
                        key: 'start',
                        titleKey: 'resources.tg.getting_started',
                        src: 'Sub-Tile-Getting-Started.jpg'
                    },
                    {
                        title: 'Finding What You Need',
                        key: 'finding',
                        titleKey: 'resources.tg.finding',
                        src: 'Sub-Tile-Finding-What-You-Need.jpg'
                    }
                ]
            }
        ]
    }

    /** Get list of daily activities */
    public getActivities() {

        return [
            { key: 'st', title: 'STEAM' },
            { key: 'll', title: 'Language & Literacy' },
            { key: 'pd', title: 'Physical Development' },
            { key: 'mm', title: 'Music & Movement' },
            { key: 'ir', title: 'Interactive Reading' },
            { key: 'cg', title: 'Cognitive' }
        ]
    }

    public getActivitiesInAge(age: string) {

        let activities = [{ key: 'in', title: 'Infant', activities: ['st', 'll', 'pd', 'mm'] },
                        { key: 't1', title: 'Younger Toddler', activities: ['st', 'll', 'pd', 'mm'] },
                        { key: 't2', title: 'Older Toddler', activities: ['st', 'll', 'pd', 'mm'] },
                        { key: 'ps', title: 'Preschool 3', activities: ['st', 'll', 'pd', 'ir', 'cg'] },
                        { key: 'pk', title: 'Pre-K 4', activities: ['st', 'll', 'pd', 'ir', 'cg'] }]


        return activities.find((x) => x.key === age).activities;
    }


    /** Get list of standards */
    public getStandards() {

        return [
            { key: 'st', title: 'STEAM' },
            { key: 'll', title: 'Language & Literacy' },
            { key: 'pd', title: 'Physical Development' },
            { key: 'al', title: 'Approaches to Learning' },
            { key: 'cg', title: 'Cognitive' }
        ]
    }

    /** Is daily view available for given age */
    public hasDailyView(age: string): boolean {

        return age === 't2' || age === 'ps' || age === 'pk';
    }

    /** Get list of classes */
    public getClasses() {

        // check if classes are already cached
        // if yes just return them
        // if no read them from API and cache the result
        if (!!this.classesCache) {

            return Promise.resolve(this.classesCache);

        } else {

            // create session header
            const session = this.appState.restore('session');
            const headers = new HttpHeaders({ 'x-vms-session': session });

            // call API to get list of teacher classes
            const classesUrl = `${this.apiRoot}/teacher/classes`;
            return this.http
                .get<Class[]>(classesUrl, { headers })
                .toPromise()
                .then((classes) => {
                    this.classesCache = classes;
                    return classes;
                });
        };
    }

    /**
     * Get lsit of students in a class
     * @param classId class idnumber
     */
    public getStudents(classId: string) {

        // check if students from specific class are already cached
        // if yes just return them
        // if no read them from API and cache the result
        const cached = this.studentsCache.find((x) => x.clas === classId);
        if (!!cached) {

            return Promise.resolve(cached.students);

        } else {

            // create session header
            const session = this.appState.restore('session');
            const headers = new HttpHeaders({ 'x-vms-session': session });

            // call API to get list of teacher reflections
            // for specific class and week
            const url = `${this.apiRoot}/teacher/students`;
            let params = new HttpParams();
            params = params.append('class', classId);

            return this.http.get<any[]>(url, { params, headers })
                .toPromise()
                .then((students) => {

                    this.studentsCache.push({ clas: classId, students: students })
                    return students;
                });
        }
    }

    /**
     * Get teacher reflections for the week.
     * Search across all teacher classes.
     * @param classId class identifier
     * @param week week number
     */
    public async getReflections(classId: string, week: string): Promise<Reflection[]> {

        // create session header
        const session = this.appState.restore('session');
        const headers = new HttpHeaders({ 'x-vms-session': session });

        // call API to get list of teacher reflections
        // for specific class and week
        const url = `${this.apiRoot}/teacher/reflection`;
        let params = new HttpParams();
        params = params.append('class', classId);
        params = params.append('week', week);

        return this.http.get<Reflection[]>(url, { params, headers })
            .toPromise();
    }

    /**
     * Get single day reflection
     * @param classId class identifier
     * @param week week number
     * @param day day number
     */
    public async getReflection(classId: string, week: string, day: string) {

        return this.getReflections(classId, week)
            .then((reflections) => {

                const dailyReflection = reflections.find((r) => r.day === day.toString());
                return !!dailyReflection ? dailyReflection.reflection : null
            });
    }

    /**
     * Save teacher reflections
     * @param classId class identifier
     * @param week week number
     * @param day day number
     * @param reflection reflection data
     * @param version content version
     */
    public async saveReflections(classId: string, week: string, day: string, reflection: any[], version: string) {

        // create session header
        const session = this.appState.restore('session');
        const headers = new HttpHeaders({ 'x-vms-session': session });

        const body = {
            class: classId,
            week,
            day,
            reflection,
            version
        };

        // call API to save teacher reflection
        const url = `${this.apiRoot}/teacher/reflection`;
        return this.http.post(url, body, { headers }).toPromise();
    }

    /**
     * Save assessment changes
     * @param id assessment id ( if mepty new assessment will be created )
     * @param classId class identifier
     * @param student username
     * @param age age code
     * @param date assessment date
     * @param assessment assessment body
     */
    public async saveAssessment(id: string, classId: string, student: string, age: string, date: number, assessment: any[]) {

        // create session header
        const session = this.appState.restore('session');
        const headers = new HttpHeaders({ 'x-vms-session': session });

        const body = {
            id,
            class: classId,
            student,
            age,
            date,
            assessment
        };

        // if id is specified call method to update assessment
        // else call method to create assessment    
        const url = `${this.apiRoot}/teacher/assessment`;
        if (!!id) {
            return this.http.post(url, body, { headers }).toPromise();
        } else {
            return this.http.put(url, body, { headers }).toPromise();
        }
    }

    /**
     * Delete assessment
     * @param classId class identifier
     * @param student student username
     * @param id assessment id
     */
    public deleteAssessment(classId: string, student: string, id: string) {

        // create session header
        const session = this.appState.restore('session');
        const headers = new HttpHeaders({ 'x-vms-session': session });

        // call API to get list of teacher reflections
        // for specific class and week
        const url = `${this.apiRoot}/teacher/assessment`;
        let params = new HttpParams();
        params = params.append('class', classId);
        params = params.append('student', student);
        params = params.append('id', id);

        return this.http.delete(url, { params, headers })
            .toPromise();
    }

    /**
     * Get assessments for a class
     * @param classId class identifier
     * @param age age group
     */
    public getAssessments(classId: string, keyAssessment: string) {

        // create session header
        const session = this.appState.restore('session');
        const headers = new HttpHeaders({ 'x-vms-session': session });

        // call API to get list of teacher reflections
        // for specific class and week
        const url = `${this.apiRoot}/teacher/assessment`;
        let params = new HttpParams();
        params = params.append('class', classId);

        return this.http.get<any[]>(url, { params, headers })
            .toPromise()
            .then((assessments) => assessments.filter((as) => as.age === keyAssessment));
    }

    /**
     * Get saved assessment result for specific assessment id
     * @param classId class identifier
     * @param student student username
     * @param id assessment id
     */
    public getAssessmentDetails(classId: string, student: string, id: string) {

        // create session header
        const session = this.appState.restore('session');
        const headers = new HttpHeaders({ 'x-vms-session': session });

        // call API to get list of teacher reflections
        // for specific class and week
        const url = `${this.apiRoot}/teacher/assessment/details`;
        let params = new HttpParams();
        params = params.append('class', classId);
        params = params.append('student', student);
        params = params.append('id', id);

        return this.http.get<any>(url, { params, headers })
            .toPromise()
    }

    public getUser() {
        const session = this.appState.restore('session');
        const headers = new HttpHeaders({ 'x-vms-session': session, 'Content-Type': 'application/json' });

        const url = `${this.apiRoot}/user/info`;

        return this.http.get(url, { headers })
            .toPromise()
            .then((user: User) => {

                return user;
            })
    }

    public updateUser(user) {

        // create session header
        const session = this.appState.restore('session');
        const headers = new HttpHeaders({ 'x-vms-session': session, 'Content-Type': 'application/json' });

        const url = `${this.apiRoot}/profile`;
        const body = {
            "username": user.username,
            "firstname": user.firstname,
            "lastname": user.lastname,
            "email": user.email,
            "password": user.password,
            "lang": user.language,
            "verified": user.verified,
        };

        return this.http.put(url, body, { headers })
            .toPromise();
    }

    public getAgeGroups() {
        const session = this.appState.restore('session');
        const headers = new HttpHeaders({ 'x-vms-session': session, 'Content-Type': 'application/json' });

        const url = `${this.apiRoot}/student/agegroups`;

        return this.http
            .get(url, { headers })
            .toPromise()
            .then((ageGroups) => {

                return ageGroups;
            })

    }
    public getSchool() {

        // create session header
        const session = this.appState.restore('session');
        const headers = new HttpHeaders({ 'x-vms-session': session });

        // call API to get list of schools
        const schoolsUrl = `${this.apiRoot}/teacher/school`;
        return this.http.get(schoolsUrl, {headers})
                        .toPromise()
                        .then((school: ISchool) =>{
                            
                            return school;
                        })
        
    }

    public searchText(query: string, language: string, age?: string, topic?: string) {
        // create session header
        const session = this.appState.restore('session');
        const headers = new HttpHeaders({ 'x-vms-session': session, 'Content-Type': 'application/json'});

        const url = `${this.apiRoot}/content/search`;
        let params = new HttpParams();
        params = params.append('query', query);
        params = params.append('language', language);
        
        if(!!age && age !== 'all'){
            params = params.append('age', age);
            
        }

        if(!!topic && topic !== 'all'){
            params = params.append('activity', topic);
            
        }

        return this.http.get(url, { params, headers }).pipe(map((data: any) => {
            return data.map((_item)=>{
                return {
                    id: _item.id,
                    age: _item.fields.age[0],
                    activity: _item.fields.activity[0],
                    type: _item.fields.type[0],
                    day: _item.fields.day ? _item.fields.day[0] : null,
                    week: _item.fields.week[0],
                    name: _item.fields.name[0],
                    topic: _item.fields.topic[0],
                    language: _item.fields.language[0],

                };
            });
            
        }));
    }

    public resetCache(){

        this.classesCache = null;
        this.studentsCache = [];
    }
}

interface Class {
    idnumber: string,
    title: string,
    age_group?: string
}

interface Student {
    username: string,
    firstname: string,
    lastname: string,
}

interface User {
    username: string,
    firstname: string,
    lastname: string,
    email: string,
    lang: string,
    password?: string,
    verified: boolean
}

interface Reflection {
    week: string,
    day: string,
    reflection: string
}
