import { Component, OnInit } from '@angular/core';
import { IClass } from 'src/app/interfaces/IClass';
import { ISchool } from 'src/app/interfaces/ISchool';
import { ITeacher } from 'src/app/interfaces/ITeacher';
import { AdminService, TeacherService } from 'src/app/services';
import { TranslateService } from '@ngx-translate/core';
import { AppState } from 'src/app/services/app.service';
import { ContentService } from 'src/app/services/content.service';

@Component({
  selector: 'app-forms',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.scss']
})

export class FormsComponent implements OnInit {

  public unknownPage: string = 'admin_tr.completed_forms';
  public day: number = null;
  public week: number = null; 
  public school: ISchool; 

  public content: any[];
  public answers: any[];
  public content_body: any[];
  public loading: boolean = false;
  private content_version: string;
  
  private tr_filter: { age: {key: string, title: string}, class: IClass, teacher: ITeacher};

  // filters
  public listAgeGroups: {key: string, title: string}[];
  public listClasses: IClass[];
  public listTeachers: ITeacher[];
  public weeks: Number[] = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52]
  public days: Number[] = [1,2,3,4,5]
  
  public currentTeacher: ITeacher = null;
  public currentAgeGroup: {key: string, title: string} = null;
  public currentClass: IClass = null;

  constructor(private appState: AppState, 
    private contentService: ContentService, 
    private translateService: TranslateService,
    private adminService: AdminService)   { 

  } 

  ngOnInit(): void {
  
    /*
    this.tr_filter = {
      age: this.appState.restore('tr_age'),
      class: this.appState.restore('tr_class'),
      teacher: this.appState.restore('tr_teacher')
    }

    this.week = this.appState.restore('week')
    this.day = this.appState.restore('day')

    console.log(this.tr_filter, this.week, this.day)

    if (!this.week) {
      this.appState.store('week', 1, true);
      this.week = this.appState.get('week');
    }

    if (!this.day) {
      this.appState.store('day', 1, true);
      this.day = this.appState.get('day');
    }

    this.currentAgeGroup = this.tr_filter.age ? this.tr_filter.age : null;
    this.currentClass = this.tr_filter.class ? this.tr_filter.class : null;
    this.currentTeacher = this.tr_filter.teacher ?  this.tr_filter.teacher : null;
    this.cd.detectChanges();
   
    */

    this.listAgeGroups = this.adminService.getAgeGroup();

    this.school = this.appState.restore('school');
    this.day = 1;
    this.week= 1;
  }

  /*
  public onSaveLocaleStorage() {
    // save filters
    this.appState.store('tr_age', this.currentAgeGroup, true);
    this.appState.store('tr_class', this.currentClass, true);
    this.appState.store('tr_teacher', this.currentTeacher, true);
    this.appState.store('day', this.day, true);
    this.appState.store('week', this.week, true);
  }
*/


  public getAgeGroup(age_group: string) {

    let age = this.adminService.getAgeGroup().find((x) => x.key === age_group);

    return age ? age.title : null;
  }

  public onChangeSchool(event: ISchool) {
    if (event) {
      this.school = event;
      this.currentAgeGroup = null;
      this.currentTeacher = null;
      this.currentClass = null;
      this.day = 1;
      this.week = 1;
      this.content = null;

      if (this.school && this.currentAgeGroup) {
        // Перевірка перед доступом до ключа
        if (this.currentAgeGroup.key) {
          this.getTeachers(this.school.idnumber, this.currentAgeGroup.key);
        }
      } 
    }
  }

  private getTeachers(schoolId, ageId): void {

    this.loading = true;
    this.adminService.getTeachersByAgeAndSchool(schoolId, ageId).then((teachers: ITeacher[]) => {

      this.listTeachers = Object.assign([], teachers.sort((a, b) => (a.firstname + a.lastname < b.firstname + b.lastname ? -1 : 1)));  
      this.loading = false;
    }).catch((error) => {
      console.log('Error get teacher: ', error);
    });
    
  }

  private getClasses(){
    this.loading = true;
    this.adminService.getActiveClassesByAgeSchoolAndTeacher(this.school.idnumber, this.currentAgeGroup.key, this.currentTeacher.username).then((classes: IClass[]) => {
      
      this.listClasses = Object.assign([], classes.sort((a, b) => (a.name < b.name ? -1 : 1)));
      if(this.listClasses.length == 1) this.currentClass = this.listClasses[0];
      this.loading = false;
    })
  }

  public onChange(type: string){
    if(type == 'ageGroup'){
      this.currentTeacher = null  
      this.currentClass = null;
      this.content = null;
      this.getTeachers(this.school.idnumber, this.currentAgeGroup.key)
    }
    if(type == 'teacher') {
      
      this.currentClass = null;
      this.content = null;
      this.getClasses()
    }
    if(type == 'class') {}
    if(type == 'week') {}
    if(type == 'day') {}
  }

  public load() {

    this.loading = true; 
    //this.onSaveLocaleStorage()

    this.adminService.getReflectionsByTeacherClassWeekDay(this.currentTeacher.username, this.currentClass.idnumber, this.week, this.day)
        .then((answers: any[]) => {

            this.answers = answers;
            this.getContent(this.currentAgeGroup.key, this.week.toString(), this.currentClass.idnumber, this.currentTeacher.username);
        })
        .catch((error) => {
            console.error('Помилка при отриманні рефлексій: ', error);
        })
        .finally(() => {
           this.loading = false; 
        });
  }


  /**
 * Load reflections per day.
 * Before loading content check if reflection was already submitted
 * @param age age code
 * @param week week number
 * @param day day number
 * @param classId class identifier
 */
  private async getContent(age: string, week: string, classId: string, teacher: string) {

    const language = this.translateService.currentLang;
   
    this.contentService.getReflections(language, age)
      .catch((err) => {
          return  language !== 'en' ? this.contentService.getReflections('en', age) : err;
      })
      .then((content) => {
            this.content = content.body;
       
            if (this.answers && this.answers[0] && this.answers[0].reflection) {
              let reflections = JSON.parse(this.answers[0].reflection);
              this.updateContentWithReflections(reflections, this.content);
            } 
           
        });
  }

  private updateContentWithReflections(reflections: any[], content: any) {

    reflections.forEach(reflection => {

        let question = content.find(q => q.id === reflection.id);
        
          if (question.type === 'LIST') {
            reflection.answers?.forEach(answerId => {
              let answer = question.answers.find(a => a.id === answerId);
              if (answer) answer.value = true; 
            });
          }
  
          if (question.type === 'OPEN_ENDED') {
            question.value = reflection.text;
          }
          this.loading = false;
    });
  }

  public goTo(type:string){
      if (type == '+'){
      if (this.day == 5){
        this.day = 1;
        if (this.week == 52){
          this.week = 1;
        } else{
            this.week++
        }
      } else{
        this.day++;
      }
    }
    if (type == '-'){
      if (this.day == 1){
        this.day = 5;
        if (this.week == 1){
          this.week = 52;
        } else{
            this.week--
        }
      } else{
        this.day--;
      }
    }  
    this.load()
  }
}
